/* eslint-disable no-loop-func */
/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import {
  TaskIterationStatusStatusEnum,
  TimeFormatEnum
} from '../api-client/generated/api';
import { timeToLocalUTC } from './general';

export const getTaskStatus = (taskIteration: any) => {
  let auxStatus = `${TaskIterationStatusStatusEnum.Created}`;
  if (
    taskIteration?.taskIteration &&
    taskIteration?.taskIteration[0] &&
    taskIteration?.taskIteration[0]?.status ===
      TaskIterationStatusStatusEnum.InProgress &&
    !taskIteration?.taskIteration[0]?.completed
  ) {
    auxStatus = TaskIterationStatusStatusEnum.InProgress;
  }

  if (
    taskIteration?.taskIteration &&
    taskIteration?.taskIteration[0] &&
    taskIteration?.taskIteration[0]?.status ===
      TaskIterationStatusStatusEnum.Completed &&
    taskIteration?.taskIteration[0]?.completed
  ) {
    auxStatus = TaskIterationStatusStatusEnum.Completed;
  }
  return auxStatus;
};

export const formatDateSimple = (auxDate: Date): string => {
  const year = auxDate.getFullYear();
  const month = auxDate.getMonth() + 1;
  const day = auxDate.getDate();
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
  return formattedDate;
};

export const cleanNumberHours = (value: string) => {
  const number = Number(value);

  if (!Number.isNaN(number)) {
    return String(number);
  }

  return number.toString();
};

export const convertTimeFormat = (timeString: string) => {
  const timeLocalTZ = timeToLocalUTC(timeString);
  return timeLocalTZ;
};

export const formatSumTime = (minutesSum: number): string => {
  if (!minutesSum) {
    return '00:00';
  }
  let value = minutesSum;
  let isNegative = '';
  if (value < 0) {
    isNegative = '-';
    value = minutesSum * -1;
  }
  const hours = Math.floor(value / 60);
  const minutes = Math.round(value % 60);

  return `${isNegative}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const decimalsToHours = (time: number | string) => {
  let isNegative = '';
  const decimalTimeString = `${time}`;
  let decimalTime = parseFloat(decimalTimeString);
  if (typeof decimalTime === 'number' && decimalTime < 0) {
    isNegative = '-';
    decimalTime *= -1;
  }
  decimalTime = decimalTime * 60 * 60;
  const hours = Math.floor(decimalTime / (60 * 60));
  decimalTime -= hours * 60 * 60;
  const minutes = Math.floor(decimalTime / 60);
  decimalTime -= minutes * 60;
  return `${isNegative}${hours < 10 ? `0${hours}` : `${hours}`}:${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }`;
};

export const hoursToDecimals = (time: string) => {
  let isNegative = false;
  let value = time;
  if (time.startsWith('-')) {
    isNegative = true;
    value = time.substring(1);
  }
  const [hours, minutes] = value.split(':').map(Number);
  let decimalTime = hours + minutes / 60;
  if (isNegative) {
    decimalTime *= -1;
  }
  return decimalTime;
};

export const formattedTime = (
  time: string,
  date?: string,
  timeFormat?: string
): string => {
  const auxDate = new Date(`${date || '2000-01-01'}T${time}`);
  return auxDate
    .toLocaleTimeString(undefined, {
      hour: '2-digit',
      hour12: !(timeFormat && timeFormat === TimeFormatEnum.F24_HOURS),
      minute: '2-digit'
    })
    .toLowerCase();
};

export const formattedTimeDateTime = (date: string): string => {
  const auxDate = new Date(date);
  return auxDate
    .toLocaleTimeString(undefined, {
      hour: '2-digit',
      hour12: true,
      minute: '2-digit'
    })
    .toLowerCase();
};

export const formatDate = (dateObj: Date) => {
  const month = dateObj
    .toLocaleString(undefined, { month: 'short' })
    .replace('.', '')
    .replace(/^\w/, (c) => c.toUpperCase());
  const day = dateObj.getUTCDate().toString();
  const year = dateObj.getFullYear();

  return `${day} ${month}, ${year}`;
};

export const getHoursDifernce = (startTime: string, endTime: string): any => {
  const startDate = new Date(`2024-02-15T${startTime}`);
  const endDate = new Date(`2024-02-15T${endTime}`);

  const differenceInMinutes = (Number(endDate) - Number(startDate)) / 60000;

  return differenceInMinutes;
};

export const formatTimesAndCalculateDifference = (
  startTime: string,
  endTime: string,
  timeFormat?: string
): any => {
  const start = Number(new Date(startTime));
  const end = Number(new Date(endTime));

  const differenceInMilliseconds = end - start;
  const differenceInMinutes = Number(
    (differenceInMilliseconds / (1000 * 60)).toFixed(3)
  );

  return {
    differenceInMinutes,
    endTimeFormatted: new Date(endTime)
      .toLocaleTimeString(undefined, {
        hour: '2-digit',
        hour12: !(timeFormat && timeFormat === TimeFormatEnum.F24_HOURS),
        minute: '2-digit'
      })
      .toLowerCase(),
    startTimeFormatted: new Date(startTime)
      .toLocaleTimeString(undefined, {
        hour: '2-digit',
        hour12: !(timeFormat && timeFormat === TimeFormatEnum.F24_HOURS),
        minute: '2-digit'
      })
      .toLowerCase()
  };
};

export const getUpcomingEvents = (events: any[]): any[] => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const formattedCurrentDate = formatDateSimple(currentDate);
  const futureEvents = events.filter((event) => {
    const auxStartTime = formatDateSimple(
      dayjs(event.start).utcOffset(event.locationTimezone).toDate()
    );

    return new Date(formattedCurrentDate) < new Date(auxStartTime);
  });

  const uniqueDates = Array.from(
    new Set(futureEvents.map((event) => event.start))
  )
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .slice(0, 3);

  return futureEvents.filter((event) => uniqueDates.includes(event.start));
};

export const getCompletedEvents = (events: any[]): any[] => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const completedEvents = events.filter((event) => {
    let banFind = false;

    if (
      event?.leadServiceTime?.lead?.projectType === 'Ad-Hoc' &&
      event.calendarEventIterations.length > 0
    ) {
      const countTaskTech = event?.leadService?.tasks.filter((task: any) => {
        if (task?.leadTaskTechnicians.length > 0) {
          const contTaskTech = task?.leadTaskTechnicians.filter(
            (tech: any) =>
              tech.technician.id ===
              event.calendarEventIterations[0].technician.id
          );
          if (contTaskTech.length > 0) {
            return task;
          }
        }
        return null;
      }).length;

      const contCompletedTechTaskIterations = event.taskIterations.filter(
        (taskIteration: any) => taskIteration.completed === true
      ).length;

      if (
        event.taskIterations.length > 0 &&
        contCompletedTechTaskIterations >= countTaskTech
      ) {
        banFind = true;
      }
    } else {
      banFind =
        event?.leadService?.tasks.filter(
          (task: any) =>
            task.taskIteration.length > 0 &&
            task.taskIteration[0]?.completed &&
            event?.calendarEventIterations.length > 0 &&
            event?.calendarEventIterations[0]?.status === 'completed'
        ).length > 0;
    }

    const eventStartDate = new Date(event.start);

    if (eventStartDate < firstDayOfMonth) {
      banFind = false;
    }

    return banFind;
  });

  const uniqueDates = Array.from(
    new Set(completedEvents.map((event) => event.start))
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return completedEvents.filter((event) => uniqueDates.includes(event.start));
};

const getDateEvents = (events: any[], currentDate: Date): any[] => {
  const formattedCurrentDate = formatDateSimple(currentDate);

  const auxEventsToday = events.filter((event: any) => {
    const auxStartTime = dayjs(event.start).utcOffset(event.locationTimezone);

    return formatDateSimple(auxStartTime.toDate()) === formattedCurrentDate;
  });

  const currentEvents = auxEventsToday.filter((event) => {
    let banFind = false;

    if (event?.leadServiceTime?.lead?.projectType === 'Ad-Hoc') {
      if (
        (event.taskIterations.length > 0 &&
          event.taskIterations.find(
            (taskIteration: any) => taskIteration.completed !== true
          )) ||
        event.taskIterations.length === 0
      ) {
        banFind = true;
      }
    } else {
      banFind =
        event?.leadService?.tasks.filter(
          (task: any) =>
            task.taskIteration.length === 0 ||
            !task.taskIteration[0]?.completed ||
            event?.calendarEventIterations.length === 0 ||
            !event?.calendarEventIterations[0]?.endTime
        ).length > 0;
    }

    return banFind;
  });
  return currentEvents;
};

export const getTodayEvents = (events: any[]): any[] => {
  const today = new Date();

  return getDateEvents(events, today);
};

export const getYesterdayEvents = (events: any[]): any[] => {
  const yesteday = new Date();
  yesteday.setDate(yesteday.getDate() - 1);
  return getDateEvents(events, yesteday);
};

export const getFormattedDatesList = (
  auxEventsToday: any[],
  // eslint-disable-next-line default-param-last
  filterCompleted = 0,
  timeFormat?: string
) => {
  let todayProjects: any[] = [];

  auxEventsToday.forEach((event: any) => {
    let auxEventData: any = {};
    const dashboardCurrentDayProject = todayProjects.find(
      (todayProject) => todayProject.projectId === event.project.id
    );

    if (!dashboardCurrentDayProject) {
      let travelTime = 0;
      let travelTimeState = 'Created';
      let totalTimeSum = 0;
      let technicianStartTime = null;
      let technicianEndTime = null;
      const auxTravelTime =
        event?.travelTimeIterations.length > 0
          ? event?.travelTimeIterations[0]
          : null;

      if (
        auxTravelTime &&
        auxTravelTime?.startTime &&
        !auxTravelTime?.completed
      ) {
        travelTimeState = 'onRoad';

        if (event?.leadServiceTime?.lead?.projectType === 'Ad-Hoc') {
          const travelTimeCalcs = formatTimesAndCalculateDifference(
            auxTravelTime?.createdAt,
            new Date().toISOString(),
            timeFormat
          );

          travelTime = travelTimeCalcs.differenceInMinutes;
          totalTimeSum += travelTimeCalcs.differenceInMinutes;
          technicianStartTime = travelTimeCalcs.startTimeFormatted;
        }
      }

      if (auxTravelTime?.completed) {
        if (event?.leadServiceTime?.lead?.projectType === 'Ad-Hoc') {
          const travelTimeCalcs = formatTimesAndCalculateDifference(
            auxTravelTime?.createdAt,
            auxTravelTime?.updatedAt,
            timeFormat
          );
          travelTime = travelTimeCalcs.differenceInMinutes;
          totalTimeSum += travelTimeCalcs.differenceInMinutes;
          technicianStartTime = travelTimeCalcs.startTimeFormatted;
        }

        travelTimeState = 'Arrived';
      }

      if (
        event?.leadServiceTime?.lead?.projectType === 'Regular' &&
        event.state !== 'Created' &&
        event?.calendarEventIterations.length > 0
      ) {
        const calendarEventIterationTimeCalcs =
          formatTimesAndCalculateDifference(
            auxTravelTime?.createdAt,
            event?.calendarEventIterations[0]?.endTime || new Date().getDate(),
            timeFormat
          );

        technicianStartTime =
          calendarEventIterationTimeCalcs.startTimeFormatted;

        if (event?.calendarEventIterations[0].status === 'completed') {
          totalTimeSum += calendarEventIterationTimeCalcs.differenceInMinutes;
          technicianEndTime = calendarEventIterationTimeCalcs.endTimeFormatted;
        }
      }

      auxEventData = {
        address: event?.location,
        calendarEventIteration: event?.calendarEventIterations[0],
        client: `${event?.client?.firstName} ${event?.client?.lastName} - ${event?.client?.businessName}`,
        date: formatDateSimple(
          dayjs(event?.start).utcOffset(event.locationTimezone).toDate()
        ),
        endAt: technicianEndTime,
        enforceGeofencing: event?.project?.enforceGeofencing,
        geofencingMeasure: event?.company?.geofencingMeasure,
        geofencingRadius: event?.company?.geofencingRadius,
        id: event?.id,
        leadServiceTimeId: event?.leadServiceTime?.id,
        locationTimezone: event?.locationTimezone || 0,
        name: event?.title,
        projectId: event?.project?.id,
        projectType: event?.leadServiceTime?.lead?.projectType,
        serviceTimes: [],
        startedAt: technicianStartTime,
        state: event?.state,
        totalTime: totalTimeSum || 0,
        travelTime,
        travelTimeData: auxTravelTime,
        travelTimeId: auxTravelTime?.id || null,
        travelTimeState
      };
    } else {
      auxEventData = dashboardCurrentDayProject;
    }

    let auxEventServiceTime: any = [];
    const eventServiceTime = auxEventData.serviceTimes.find(
      (servTime: any) => servTime.id === event?.leadServiceTime?.id
    );

    if (!eventServiceTime) {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);

      const supervisor = {
        backgroundColor: `rgb(${red}, ${green}, ${blue})`,
        firstName: event?.leadServiceTime?.supervisor?.firstName,
        id: event?.leadServiceTime?.supervisor?.id,
        image: {
          url: ''
        },
        lasName: event?.leadServiceTime?.supervisor?.lastName,
        name: `${event?.leadServiceTime?.supervisor?.firstName} ${event?.leadServiceTime?.supervisor?.lastName}`,
        profilePic: null
      };

      auxEventServiceTime = {
        configuredTravelTime: event?.leadServiceTime?.travelTime || 0,
        endTime: event?.leadServiceTime?.endTime,
        id: event?.leadServiceTime?.id,
        services: [],
        startTime: event?.leadServiceTime?.startTime,
        supervisors: event?.leadServiceTime?.assignSupervisor
          ? [supervisor]
          : []
      };
    } else {
      auxEventServiceTime = eventServiceTime;
    }

    let auxEventService: any = [];
    const eventService = auxEventServiceTime?.services.find(
      (service: any) => service.id === event?.leadService?.id
    );

    let completedTasks = false;

    if (!eventService) {
      const taskTechnicianColors: any[] = [];

      event?.leadService?.tasks.forEach((task: any) => {
        const newTecnicians = task?.leadTaskTechnicians.map(
          (technicianData: any) => {
            const technician = technicianData?.technician;
            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);

            return {
              backgroundColor: `rgb(${red}, ${green}, ${blue})`,
              firstName: technician?.firstName,
              id: technician?.id,
              image: {
                url: ''
              },
              lasName: technician?.lastName,
              name: `${technician?.firstName} ${technician?.lastName}`,
              profilePic: null,
              rate: technician?.rate
            };
          }
        );
        let auxTaskIteration = task?.taskIteration;

        if (
          task?.taskIteration.length > 0 &&
          task?.taskIteration[0] &&
          !task?.taskIteration[0]?.calendarEvents
        ) {
          auxTaskIteration = [];
        }

        let pushTask = false;
        let setTaskTime = false;

        if (filterCompleted > 0 && task?.taskIteration.length > 0) {
          if (
            filterCompleted === 1 &&
            task?.taskIteration.length > 0 &&
            task?.taskIteration[0].completed &&
            task?.taskIteration[0].status ===
              TaskIterationStatusStatusEnum.Completed
          ) {
            pushTask = true;
            setTaskTime = true;
          }

          if (filterCompleted === 2) {
            pushTask = true;
            setTaskTime = true;
          }
        } else pushTask = true;

        let taskWorkedTime = 0;

        if (setTaskTime && task?.taskIteration[0].startTime) {
          const taskTimeCalcs = formatTimesAndCalculateDifference(
            task?.taskIteration[0]?.startTime,
            task?.taskIteration[0].endTime || new Date().toISOString(),
            timeFormat
          );

          if (event?.leadServiceTime?.lead?.projectType === 'Ad-Hoc') {
            auxEventData.totalTime += taskTimeCalcs.differenceInMinutes;
          }

          taskWorkedTime = taskTimeCalcs.differenceInMinutes;
        }

        if (pushTask) {
          taskTechnicianColors.push({
            ...task,
            configTravelTime: event?.leadServiceTime?.travelTime || 0,
            status: getTaskStatus(task),
            taskIteration: auxTaskIteration,
            taskWorkedTime,
            technicians: newTecnicians
          });
        }
      });

      auxEventService = {
        id: event?.leadService?.id,
        name: event?.leadService?.name,
        tasks: taskTechnicianColors
      };
    } else {
      auxEventService = eventService;
    }

    let incomplete = false;

    auxEventService.tasks.forEach((task: any) => {
      if (task?.taskIteration.length > 0 && !task?.taskIteration[0].completed) {
        incomplete = true;
      } else if (!incomplete && task?.taskIteration?.length === 0) {
        incomplete = true;
      }
    });

    if (!incomplete) {
      completedTasks = true;
    }

    const antServices = auxEventServiceTime?.services.filter(
      (service: any) => service.id !== auxEventService?.id
    );

    auxEventServiceTime = {
      ...auxEventServiceTime,
      completedTasks,
      services: [...antServices, auxEventService]
    };

    const antServiceTimes = auxEventData?.serviceTimes.filter(
      (servTime: any) => servTime.id !== auxEventServiceTime?.id
    );

    auxEventData = {
      ...auxEventData,
      completedTasks,
      serviceTimes: [...antServiceTimes, auxEventServiceTime]
    };

    const antTodayProjects = todayProjects.filter(
      (todayProject) => todayProject.projectId !== event.project.id
    );
    todayProjects = [...antTodayProjects, auxEventData];
  });

  return todayProjects;
};

export const getTaskInProgress = (events: any[]): any => {
  const currentDate = new Date();
  const pastEvents = events.filter((event) => {
    const auxEventDate = new Date(
      new Date(
        `${formatDateSimple(new Date(event.start))} 23:59:59`
      ).toISOString()
    );

    return (
      auxEventDate < currentDate &&
      event.taskIterations.length > 0 &&
      event.state === 'Started'
    );
  });

  let banFind: any = null;

  if (pastEvents.length > 0) {
    pastEvents.forEach((pastEvent: any) => {
      pastEvent.leadService.tasks.forEach((task: any) => {
        if (
          task.taskIteration[0] &&
          task.taskIteration[0]?.status ===
            TaskIterationStatusStatusEnum.InProgress &&
          banFind === null
        ) {
          banFind = { pastEvent, task };
        }
      });
    });
  }

  return banFind;
};

export function userDashboardParser(response: any, timeFormat?: string) {
  const { data } = response;
  const upcomingEvents: any[] = [];
  const completedEvents: any[] = [];

  const prevTaskInProgres = getTaskInProgress(data);

  const auxEventsToday = getTodayEvents(data);

  const todayProjects = getFormattedDatesList(auxEventsToday, 2, timeFormat);

  const auxEventsYesterday = getYesterdayEvents(data);
  const yesterdayProjects = getFormattedDatesList(
    auxEventsYesterday,
    2,
    timeFormat
  );

  const upcomingEventsList = getUpcomingEvents(data);
  const upcomingEventsListDates: any[] = [];
  upcomingEventsList.forEach((event) => {
    const currentEventDate = upcomingEventsListDates.find(
      (upcomingEvent) => upcomingEvent.date === event.start
    );

    if (!currentEventDate) {
      upcomingEventsListDates.push({
        date: event.start,
        events: [event]
      });
    } else {
      currentEventDate.events.push(event);
    }
  });

  upcomingEventsListDates.forEach((eventsDateList) => {
    const formatedDatesList = getFormattedDatesList(
      eventsDateList.events,
      0,
      timeFormat
    );
    upcomingEvents.push({
      date: formatDateSimple(new Date(eventsDateList.date)),
      events: formatedDatesList
    });
  });

  const completedEventsList = getCompletedEvents(data);
  const completeEventsListDates: any[] = [];

  completedEventsList.forEach((event) => {
    const completedEventDate = completeEventsListDates.find(
      (completedEvent) => completedEvent.date === event.start
    );

    if (!completedEventDate) {
      completeEventsListDates.push({
        date: event.start,
        events: [event]
      });
    } else {
      completedEventDate.events.push(event);
    }
  });

  completeEventsListDates.forEach((eventsDateList) => {
    const formatedDatesList = getFormattedDatesList(
      eventsDateList.events,
      1,
      timeFormat
    );
    completedEvents.push({
      date: formatDateSimple(new Date(eventsDateList.date)),
      events: formatedDatesList
    });
  });

  return {
    completedEvents,
    prevTaskInProgres,
    todayProjects,
    upcomingEvents,
    yesterdayProjects
  };
}
