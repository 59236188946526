import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  styled,
  SliderProps,
  Avatar,
  useTheme
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { border } from '@mui/system';
import RegularInput from '../../../../components/input/RegularInput';
import api from '../../../../api';
import {
  formatDateFull,
  formatDateTime,
  sortByDay
} from '../../../../utils/general';
import { dayFullDateParser } from '../../../../utils/widgetHelper';
import ToastNotification from '../../../../components/shared/ToastNotification';
import { toastNotification } from '../../../../utils/notificationHelper';

const MsgContainer = styled(Box)<SliderProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.primary[100]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2)
}));

interface Props {
  // can be the proposal id or the token
  clientView?: boolean;
  id: string;
  messages: Array<any>;
  updateMessages: (values: any) => void;
}

const Chat: React.FC<Props> = function Chat({
  id,
  messages,
  clientView,
  updateMessages
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [postMessageApi, setPostMessageApi] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();
  const messageRef = useRef<any>();

  const {
    data: postMessageData,
    mutate: postMessageMutate,
    error: postMessageError
  } = useSWR(
    postMessageApi ? { id, message, url: 'postMessage' } : null,
    clientView
      ? api.ProposalApi.postClientMessage
      : api.ProposalApi.postOperatorMessage
  );

  const clearApiResponse = () => {
    postMessageMutate(undefined, true);
  };

  // We only can reset the component content disabled enable it
  const inputBox = () => {
    return (
      <Box alignItems="center" display="flex" gap={2} pl={2} pr={2}>
        <Box display="flex" justifyContent="center" width="100%">
          {postMessageApi && (
            <RegularInput
              disabled
              customStyle={{
                backgroundColor: `${theme.palette.background.paper} !important`,
                border: `1px solid ${theme.palette.secondary[100]}`,
                height: '46px',
                width: '100%'
              }}
            />
          )}
          {!postMessageApi && (
            <RegularInput
              customStyle={{
                backgroundColor: `${theme.palette.background.paper} !important`,
                border: `1px solid ${theme.palette.secondary[100]}`,
                height: '46px',
                width: '100%'
              }}
              onChange={(value: any) => {
                setMessage(value);
              }}
              value={message}
            />
          )}
        </Box>
        <Button
          disabled={!!toastNotification}
          onClick={() => {
            if (!message || message === '') {
              setToastNotification({
                text: t('NOTIFICATION.TOAST.messageEmpty'),
                type: 'error'
              });
            } else {
              clearApiResponse();
              setPostMessageApi(true);
            }
          }}
          sx={{ width: isMobile ? 50 : 150 }}
          variant="primary"
        >
          <Typography
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
            variant="primary-bold"
          >
            {!isMobile && t('LEAD.CREATE.PROPOSAL.CHAT.button')}{' '}
          </Typography>
        </Button>
      </Box>
    );
  };

  const messageItem = (
    name: string,
    date: string,
    text: string,
    sendByType: string
  ) => {
    const order = sendByType === 'operator' ? 0 : 1;
    return (
      <MsgContainer>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          width="100%"
        >
          <Box
            alignItems="center"
            display="flex"
            gap={2}
            order={order}
            width="100%"
          >
            <Box alignItems="center" display="flex" gap={2} order={order}>
              <Avatar />
            </Box>
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              width="100%"
            >
              <Typography color="primary" order={order} variant="heading_300">
                {name}
              </Typography>
              <Typography color="secondary" variant="body_200">
                {date}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2} textAlign={order ? 'right' : 'left'} width="100%">
          <Typography variant="body_200">{text}</Typography>
        </Box>
      </MsgContainer>
    );
  };

  const getMessages = () => {
    const getMessagesParsed = sortByDay(messages, 'sentAt');
    const keys = Object.keys(getMessagesParsed);
    return keys.map((date: string) => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }} width="100%">
          <Typography color="secondary" variant="secondary">
            {dayFullDateParser(date)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
        >
          {getMessagesParsed?.[date].map((item: any) => {
            const name = item?.operator
              ? `${item?.operator?.firstName} ${item?.operator?.lastName}`
              : `${item?.client?.firstName} ${item?.client?.lastName}`;
            return messageItem(
              name,
              formatDateTime(item?.sentAt),
              item?.message,
              item?.sendByType
            );
          })}
        </Box>
      </Box>
    ));
  };

  useEffect(() => {
    if (postMessageData) {
      setMessage('');
      setPostMessageApi(false);
      updateMessages(postMessageData);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.messageSent'),
        type: 'success'
      });
    }
  }, [postMessageData]);

  useEffect(() => {
    if (postMessageError) {
      setPostMessageApi(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.messageFail'),
        type: 'error'
      });
    }
  }, [postMessageError]);

  useEffect(() => {
    setTimeout(() => {
      if (messageRef?.current) {
        messageRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
      }
    }, 100);
  }, [messages]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      pl={2}
      pr={2}
      pt={5}
      width="100%"
    >
      <Typography pl={2} variant="heading_400">
        {clientView
          ? t('LEAD.CREATE.PROPOSAL.CHAT.clientTitle')
          : t('LEAD.CREATE.PROPOSAL.CHAT.operatorTitle')}
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        height="180%"
        overflow="scroll"
        padding={2}
      >
        {' '}
        {messages?.length > 0 && getMessages()}
        <Box ref={messageRef} />
      </Box>
      {inputBox()}
      {toastNotification && (
        <ToastNotification
          onClose={() => {
            setToastNotification(undefined);
          }}
          text={toastNotification.text}
          type={toastNotification.type}
        />
      )}
    </Box>
  );
};

Chat.defaultProps = {
  clientView: false
};

export default Chat;
