import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface EditButtonProp {
  disabled?: boolean;
  handleOpenDraw: any;
  tooltip?: String | undefined | null;
}

const EditButton: React.FC<EditButtonProp> = function EditButton({
  handleOpenDraw,
  tooltip,
  disabled
}) {
  return (
    <Button
      disabled={disabled}
      onClick={handleOpenDraw}
      style={{ minWidth: '45px' }}
      variant="table"
    >
      <Typography variant="primary-bold">
        <Tooltip arrow title={tooltip}>
          <i className="custom-icon icon-edit" />
        </Tooltip>
      </Typography>
    </Button>
  );
};

EditButton.defaultProps = {
  disabled: false,
  tooltip: ''
};

export default EditButton;
