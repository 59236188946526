/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable no-loop-func */
/* eslint-disable import/prefer-default-export */

import dayjs from 'dayjs';
import { Order } from '../types';
import { weekDaysIndex, weekNumberIndex } from '../helpers/weekDays';
import {
  accentColors,
  alertColors,
  mainColors,
  secondaryColors,
  surfaceColors,
  warningColors,
  successColors
} from '../theme/themeConfig';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Theme } from '@mui/material';
import { Timezone } from './timeZone.enum';
import tzlookup from 'tz-lookup';
import { TimeFormatEnum } from '../api-client/generated';

dayjs.extend(utc);
dayjs.extend(timezone);

const duration = require('dayjs/plugin/duration');

export interface roleName {
  [key: string]: string;
}

export function capitalizeString(value: string) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
  return '';
}

export function cleanPhoneNumber(value: string) {
  const toDelete = ['(', ')', '-', ' '];
  let toBack = value;
  toDelete.forEach((char) => {
    toBack = toBack.replaceAll(char, '');
  });
  return toBack;
}

export function validatePhoneNumber(value: string) {
  if (!value || value === '') {
    return undefined;
  }
  const number = cleanPhoneNumber(value);
  if (number.length === 10) {
    return `+1${number}`;
  }
  if (number.length === 11) {
    return number;
  }
  return undefined;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  // eslint-disable-next-line no-unused-vars
  a: { [key in Key]: number | string },
  // eslint-disable-next-line no-unused-vars
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line no-unused-vars
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function validRegexStringRules(rules: Array<any>, value: string) {
  const error: any[] = [];
  rules.forEach((rule) => {
    if (rule.enabled && !value.match(rule.regex)) {
      error.push(rule.message);
    }
  });
  return error.length
    ? { error: true, errorMessage: error.toString().replaceAll(',', ', ') }
    : { error: false, errorMessage: '' };
}

export function getCurrentMeasure(measureList: any[], value: any) {
  if (value && value.measure !== '') {
    const currentMeasure = measureList.find(
      (item: any) => item.id === value.measure
    );
    return currentMeasure || {};
  }
  return {};
}

export const serviceCatalogRouteMap = {
  Category: 'categories',
  Client: 'clients',
  Lead: 'leads',
  Material: 'materials',
  ProposalMessages: 'proposal-messages',
  Service: 'services',
  Skill: 'skills',
  Task: 'tasks',
  User: 'users',
  Widgets: 'widgets',
  Checklist: 'checklists'
};

export const resourseColors = [
  '#68A550',
  '#E79137',
  '#59A9CF',
  '#C7373B',
  '#CBCCD3',
  '#A2E9F6',
  '#E1EBF1',
  '#1A1A1B',
  '#F5D3AF'
];

export function printArray(toPrint: Array<string>, t: any) {
  let toBack = '';
  toPrint.forEach((item: string, index: number) => {
    if (index + 1 === toPrint.length) {
      toBack += ` ${t('GENERAL.and')} ${item}`;
    } else {
      toBack += `${item}, `;
    }
  });
  return toBack;
}

export function convertDate(date: string) {
  return dayjs(date);
}

export function formatDate(date: any) {
  return dayjs(date).format('MM/DD/YYYY');
}

export function formatDateCalendarPopUp(date: any) {
  return dayjs(date).format('MM/DD');
}

export function formatDateFull(date: any, timeFormat?: string) {
  if (timeFormat && timeFormat === TimeFormatEnum.F24_HOURS) {
    return dayjs(date).utc().local().format('dddd, MMMM D, YYYY HH:mm');
  }

  return dayjs(date).utc().local().format('dddd, MMMM D, YYYY h:mm A');
}

export function formatDateTime(date: any) {
  return dayjs(date).utc().local().format('h:mm A');
}

export function getFrequency(serviceTime: any, t: any) {
  if (undefined !== serviceTime?.frequency) {
    return `${serviceTime.frequency} ${
      serviceTime.startDate ? `from ${formatDate(serviceTime.startDate)}` : ''
    } ${serviceTime.endDate ? `to ${formatDate(serviceTime.endDate)}` : ''}`;
  }
  return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
}
export function getFrequencyWithDays(serviceTime: any, t: any) {
  if (undefined !== serviceTime?.frequency) {
    return `${serviceTime.frequency} ${
      serviceTime.frequencyDays
        ? ` on ${printArray(serviceTime.frequencyDays, t)}`
        : ''
    } ${
      serviceTime.startDate ? `from ${formatDate(serviceTime.startDate)}` : ''
    } ${serviceTime.endDate ? `to ${formatDate(serviceTime.endDate)}` : ''}`;
  }
  return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
}

export function getCurrency(currency: any) {
  if (Number.isNaN(parseFloat(currency)) && currency?.includes('$')) {
    // Validation to avoid convert a currency already converted
    return currency;
  }
  const USD = Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency'
  });
  return USD.format(parseFloat(currency));
}

export function getWeekMonth(input: any) {
  const date = new Date(input);
  const firstWeekday = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  const offsetDate = date.getDate() + firstWeekday - 1;
  return Math.floor(offsetDate / 7);
}

export function getVisitInfo(values: any) {
  const {
    frequency,
    frequencyDays,
    startDate: start,
    endDate: end,
    frequencyWeek
  } = values;
  const empty = { days: 0, visits: 0, weeks: 0 };
  dayjs.extend(duration);
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  if (endDate < startDate) {
    return empty;
  }
  let currentDate = startDate;
  let weekNumber = 1;
  let visits = 0;
  let days = 1;
  while (formatDate(currentDate) !== formatDate(endDate)) {
    switch (frequency) {
      case 'weekly':
        frequencyDays.forEach((day: any) => {
          if (weekDaysIndex[day] === currentDate.day()) {
            visits += 1;
          }
        });
        break;
      case 'biweekly':
        if (weekNumber % 2 !== 0) {
          frequencyDays.forEach((day: any) => {
            if (weekDaysIndex[day] === currentDate.day()) {
              visits += 1;
            }
          });
        }
        break;
      case 'monthly':
        if (frequencyWeek) {
          const weekIndex = weekNumberIndex[frequencyWeek];
          if (getWeekMonth(formatDate(currentDate)) === weekIndex) {
            frequencyDays.forEach((day: any) => {
              if (weekDaysIndex[day] === currentDate.day()) {
                visits += 1;
              }
            });
          }
        }
        break;
      default:
        break;
    }
    if (currentDate.day() === 0 && currentDate !== startDate) {
      weekNumber += 1;
    }
    days += 1;
    currentDate = currentDate.add(1, 'day');
  }
  return { days, visits, weeks: weekNumber };
}

export const getSalesTaxableItems = (field: string, taxableItems: any) => {
  let total = 0.0;
  if (taxableItems?.length > 0) {
    taxableItems.forEach((taxableItem: any) => {
      switch (field) {
        case 'total':
          total += parseFloat(taxableItem.total || 0);
          break;
        case 'tax':
          total += parseFloat(taxableItem.subTotal || 0);
          break;
        case 'subtotal':
          total += parseFloat(taxableItem.subTotal || 0);
          break;
        default:
          total += parseFloat(taxableItem.total || 0);
          break;
      }
    });
  }
  return total;
};

export const getMiscCost = (misc: any) => {
  let total = 0.0;
  if (misc?.length > 0) {
    misc.forEach((misc: any) => {
      total += parseFloat(misc.total || 0);
    });
  }
  return total;
};

interface regularInputTypeProps {
  [key: string]: string;
}

export const regularInputType: regularInputTypeProps = {
  cost: 'number',
  subTotal: 'number',
  summaryCost: 'number',
  total: 'number'
};

export function getMonthString(type: 'short' | 'normal') {
  if (type === 'short') {
    return [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
  }

  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
}

export const ProjectStatus = {
  CANCELLATION_REQUESTED: 'Cancellation Requested',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  RE_SCHEDULE: 'Re-schedule',
  SCHEDULED: 'Scheduled'
} as const;

const activeStyle = {
  active: successColors.SUCCESS_4,
  backgroundColor: successColors.SUCCESS_1,
  border: '1px solid #3E8C32',
  borderRadius: '24px !important',
  color: '#3CAC20'
};

const inactiveStyle = {
  active: secondaryColors.SECONDARY_4,
  backgroundColor: secondaryColors.SECONDARY_1,
  border: `1px solid ${secondaryColors.SECONDARY_5}`,
  borderRadius: '24px !important',
  color: secondaryColors.SECONDARY_5
};

const pendingStyle = {
  active: warningColors.ERROR_4,
  backgroundColor: warningColors.ERROR_1,
  border: `1px solid ${warningColors.ERROR_5}`,
  borderRadius: '24px !important',
  color: warningColors.ERROR_5
};

const pendingStyleInvoice = {
  backgroundColor: '#FFFFF4',
  border: '1px solid #F7851F',
  borderRadius: '24px !important',
  color: '#F7851F'
};

const overdueStyleInvoice = {
  active: alertColors.ALERT_4,
  backgroundColor: alertColors.ALERT_1,
  border: `1px solid ${alertColors.ALERT_5}`,
  borderRadius: '24px !important',
  color: alertColors.ALERT_5
};

const scheduleStyle = {
  active: mainColors.MAIN_4,
  backgroundColor: mainColors.MAIN_1,
  border: '1px solid #3396FF',
  borderRadius: '24px !important',
  color: '#3396FF'
};

const canceledStyle = {
  active: secondaryColors.SECONDARY_4,
  backgroundColor: secondaryColors.SECONDARY_1,
  border: `1px solid ${secondaryColors.SECONDARY_5}`,
  borderRadius: '24px !important',
  color: secondaryColors.SECONDARY_5
};

const completedStyle = {
  active: successColors.SUCCESS_4,
  backgroundColor: successColors.SUCCESS_1,
  border: `1px solid ${successColors.SUCCESS_5}`,
  borderRadius: '24px !important',
  color: successColors.SUCCESS_5
};

export const rolesStyle = {
  backgroundColor: '#D9F2F0',
  border: '1px solid #68C0BA',
  borderRadius: '24px !important',
  color: '#0F5C56'
};

export const skillStyle = {
  backgroundColor: '#F4FBFF',
  border: '1px solid #A2CDEC',
  borderRadius: '24px !important',
  color: '#005DA2'
};

export const chipDaysStyle = {
  alignItems: 'center',
  backgroundColor: '#F4FBFF',
  border: '1px solid #A2CDEC',
  borderRadius: '20px !important',
  color: '#005DA2',
  display: 'flex',
  justifyContent: 'center'
};

export const checkStyle = {
  backgroundColor: '#F9F4FF',
  border: '1px solid #BA86FF',
  borderRadius: '24px !important',
  color: '#005DA2'
};

export const statusStyle = (v: string, type?: string) => {
  const value = v?.toLocaleLowerCase();
  switch (value) {
    case 'converted':
    case 'completed':
    case 'paid':
    case 'all assigned':
      return completedStyle;
    case 'active':
    case 'approved':
      return activeStyle;
    case 'will require assign':
    case 'scheduled':
    case 'retrieved':
    case 'not started':
      return scheduleStyle;
    case 'inactive':
    case 'archived':
    case 'draft':
    case 'created':
      return inactiveStyle;
    case 'pending':
    case 'working lead':
    case 'in progress':
    case 'inProgress':
    case 'required assign':
    case 'awaiting':
      return pendingStyle;
    case 'canceled':
      return canceledStyle;
    case 'expired':
    case 'rejected':
    case 'lost':
    case 'urgent required assign':
    case 'cancelled':
    case 'overdue':
      return overdueStyleInvoice;
    default:
      return activeStyle;
  }
};

export const getColor = (min: number, current: number) => {
  if (current <= 0) {
    return 'error.main';
  }
  if (current <= min) {
    return 'warning.main';
  }
  if (current > min) {
    return 'success.main';
  }
  return '';
};

export const getStatusForRangeDate = (calendarState: string) => {
  if (calendarState === 'InProgress' || calendarState === 'Created') {
    return {
      bg: warningColors.LIGHT_ERROR,
      txt: warningColors.ERROR
    };
  }

  if (calendarState === 'Completed') {
    return {
      bg: accentColors.ACCENT_6,
      txt: accentColors.ACCENT_2
    };
  }

  if (calendarState === 'Awaiting') {
    return {
      bg: mainColors.MAIN_LIGHT,
      txt: mainColors.MAIN_8
    };
  }

  return {
    bg: mainColors.MAIN_LIGHT,
    txt: mainColors.MAIN_8
  };
};

export const generalIconStatus: any = {
  approved: 'ok',
  awaiting: 'clock',
  created: 'help',
  error: 'warning',
  information: 'info',
  rejected: 'warning',
  retrieved: 'cancelCircle',
  warning: 'warning'
};

export const getCustomColorBasedOnStatus = (type: string, theme: Theme) => {
  if (type) {
    switch (type) {
      case 'approved':
      case 'success':
      case 'Working Lead':
      case 'Converted':
        return theme.palette.success;
      case 'Draft':
      case 'information':
      case 'info':
        return theme.palette.informative;
      case 'delete':
      case 'error':
        return theme.palette.alert;
      case 'warning':
        return theme.palette.warning;
      default:
        return theme.palette.surface;
    }
  }
  return theme.palette.surface;
};

// This function transforms the date and time from the backend
export const addTimezoneToBackendDate = (DateData: Date, TimeDate?: string) => {
  const time = TimeDate || '00:00';
  const date = new Date(`${DateData} ${time} ${Timezone.GMT_0}`);
  return dayjs(date).format('MM/DD/YYYY hh:mm A');
};

export const getTimeFromISO8601 = (
  isoDateTime: string,
  timeFormat?: string
) => {
  const fechaUtcDate = new Date(isoDateTime);
  const horas = fechaUtcDate.getHours();
  const minutos = fechaUtcDate.getMinutes();
  const ampm = horas >= 12 ? 'pm' : 'am';
  const hora12 = horas % 12 || 12; // Convert time to 12-hour format
  const horaLocal = `${
    timeFormat && timeFormat === TimeFormatEnum.F24_HOURS ? horas : hora12
  }:${minutos < 10 ? '0' : ''}${minutos} ${
    timeFormat && timeFormat === TimeFormatEnum.F24_HOURS ? '' : ampm
  }`;
  return horaLocal;
};

export const getFormatedHoursFromMilliseconds = (ms: number) => {
  const totalMinutes = Math.floor(ms / 60000);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export type TaskStatus =
  | 'Completed'
  | 'completed'
  | 'awaiting'
  | 'created'
  | 'inProgress';

export type TaskStyle = {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
};

export type TaskStyles = {
  [key in TaskStatus]: TaskStyle;
};

export const taskInteractionStyles: TaskStyles = {
  Completed: {
    backgroundColor: accentColors.ACCENT_6,
    borderColor: accentColors.ACCENT_2,
    textColor: accentColors.ACCENT_2
  },

  // default: {
  //   backgroundColor: 'transparent',
  //   borderColor: 'transparent',
  //   textColor: 'inherit'
  // },
  awaiting: {
    backgroundColor: surfaceColors.SURFACE_5,
    borderColor: mainColors.MAIN_6,
    textColor: mainColors.MAIN_6
  },

  completed: {
    backgroundColor: accentColors.ACCENT_6,
    borderColor: accentColors.ACCENT_2,
    textColor: accentColors.ACCENT_2
  },

  created: {
    backgroundColor: surfaceColors.SURFACE_5,
    borderColor: mainColors.MAIN_6,
    textColor: mainColors.MAIN_6
  },

  inProgress: {
    backgroundColor: alertColors.ALERT_6,
    borderColor: alertColors.ALERT_7,
    textColor: alertColors.ALERT_7
  }
};

export const taxTypes = ['payroll', 'other'];

export const getLocationTimeZone = (lat: number, lng: number) => {
  if (lat && lng) {
    const locationTime = tzlookup(lat, lng);
    const utcDate = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'UTC' })
    );
    const tzDate = new Date(
      new Date().toLocaleString('en-US', { timeZone: locationTime })
    );
    const tzOffset = (tzDate.getTime() - utcDate.getTime()) / 6e4;
    return { timezone: tzOffset, timezoneIANA: locationTime };
  }
  return null;
};

export const getNumberForDatesParser = (number: any) => {
  const n = Number(number);
  return n < 10 ? `0${n}` : n;
};

export const convertTo12HourFormat = (
  time24: string | undefined,
  timezone?: number | undefined,
  timeFormat?: string
) => {
  if (!time24) return '';
  const [hours, minutes, seconds] = time24.split(':').map(Number);
  const time = dayjs().utc().hour(hours).minute(minutes).second(seconds);
  if (timezone) time.add(timezone, 'minute');

  const dateTime = time.toDate();

  const time12 = dateTime.toLocaleTimeString(undefined, {
    hour12: !(timeFormat && timeFormat === TimeFormatEnum.F24_HOURS)
  });
  const [formattedTime, period] = time12.split(' ');
  const formattedHours = formattedTime.split(':')[0];
  return `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${period.toLocaleLowerCase()}`;
};

export const getFormatedTimeByTimeZone = (
  startTime: string | undefined,
  timezone: number,
  force24HoursFormat?: boolean | undefined
) => {
  if (!startTime) return '';
  const time = dayjs(startTime).utcOffset(timezone);

  const formatoHora = force24HoursFormat ? 'HH:mm' : 'h:mm A';

  return time.format(formatoHora);
};

export const convertDecimalToHora = (decimal: number) => {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};

export function convertToCurrentTimeZone(dateString: string) {
  // We add 'Z' to indicate that it is in UTC.
  const stringWithZ = `${dateString}Z`;
  const date = new Date(stringWithZ);
  return date.toLocaleString('en-US');
}

export function timeToLocalUTC(time24: string, force24H?: boolean) {
  if (!time24) return '';
  const [hours, minutes, seconds] = time24.split(':').map(Number);
  const time = dayjs().utc().hour(hours).minute(minutes).second(seconds);
  const timezoneOffsetLocal = new Date().getTimezoneOffset();
  if (timezoneOffsetLocal) time.add(-timezoneOffsetLocal, 'minute');

  const dateTime = time.toDate();
  const options = { hour12: !force24H };
  const time12 = dateTime.toLocaleTimeString('en-US', options);
  const [formattedTime, period] = time12.split(' ');
  const formattedHours = formattedTime.split(':')[0];
  return `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${period.toLocaleLowerCase()}`;
}

export function timeTimezoneToUTC(
  time: string, // 00:00
  timezone: string = 'America/Chicago' // iana
) {
  if (!time) return '';
  const timeInTimezone = dayjs.tz(time, 'HH:mm', timezone);
  const utcTime = timeInTimezone.utc();
  return utcTime.format('HH:mm');
}

export function convertUTCToLocalTimeZone(
  time: string,
  timeZone: string = 'America/Chicago', // iana
  force24H: boolean = true
): string {
  const utcTime = dayjs.utc(time, 'HH:mm');
  const localTime = utcTime.tz(
    timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  ); // Convert the time to the specified timezone

  return localTime.format(force24H ? 'HH:mm' : 'h:mm A');
}

export function convertUTCToLeadTimezone(
  input: string, // format: "00:00:00" or YYYY-MM-DDTHH:MM.SSSZ"
  offset: number | string // format: offset in minutes (number) or IANA (string)
): string {
  let date: dayjs.Dayjs;
  if (input?.includes('T')) {
    date = dayjs.utc(input);
  } else {
    const [utcHours, utcMinutes, utcSeconds] = input.split(':').map(Number);
    date = dayjs().utc().hour(utcHours).minute(utcMinutes).second(utcSeconds);
  }
  let offsetInUse: number;
  if (typeof offset === 'string') {
    const offsetInHours = dayjs().tz(offset).utcOffset();
    offsetInUse = offsetInHours;
  } else {
    offsetInUse = offset;
  }
  date = date.add(offsetInUse, 'minute');
  const localHours = String(date.hour()).padStart(2, '0');
  const localMinutes = String(date.minute()).padStart(2, '0');
  const localSeconds = String(date.second()).padStart(2, '0');
  return `${localHours}:${localMinutes}:${localSeconds}`;
}

export function downloadAll(imgs: Array<String>) {
  imgs.forEach((image: any, index: number) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = image.large || image.fileURL;
      link.download = `image-${index + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000 * index);
  });
}

export function getPercentageOfDaysCompleted(
  startDate: string,
  endDate: string,
  status: string
) {
  if (!startDate || !endDate || status.toLowerCase() === 'cancelled') {
    return 100;
  }
  const startDayFormat = dayjs(startDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  const endDayFormat = dayjs(endDate, 'MM-DD-YYYY').format('YYYY-MM-DD');
  const start = new Date(startDayFormat);
  const end = new Date(endDayFormat);
  const today = new Date();
  if (end.getTime() < today.getTime()) {
    return 100;
  }
  if (start.getTime() > today.getTime()) {
    return 0;
  }
  const totalDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
  const daysCompleted =
    (today.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);
  const percentageCompleted = (daysCompleted / totalDays) * 100;
  return Math.min(Math.max(percentageCompleted, 0), 100).toFixed(2);
}

export function getColorByPercent(percent: number) {
  if (percent === 100) {
    return successColors.SUCCESS_3;
  }
  if (percent === 0) {
    return alertColors.ALERT_3;
  }
  return warningColors.ERROR_3;
}

export function sortByPercentAndRate(data: any[]) {
  return data.sort((a: any, b: any) => {
    if (a.percent === b.percent) {
      return a.rate - b.rate;
    }
    return b.percent - a.percent;
  });
}

export function sortByDay(data: Array<any>, key: string) {
  data.sort((a: any, b: any) => +new Date(a[key]) - +new Date(b[key]));

  const newData = data.map((item: any) => {
    return {
      ...item,
      current: formatDate(item?.[key])
    };
  });

  const groupedByDate = newData.reduce((acc: any, item: any) => {
    const date = item?.current;
    const result = { ...acc };

    if (!result[date]) {
      result[date] = [];
    }

    result[date].push(item);

    return result;
  }, {});

  return groupedByDate;
}

export const sorterDays: any = {
  friday: 5,
  monday: 1,
  saturday: 6,
  sunday: 0,
  thursday: 4,
  tuesday: 2,
  wednesday: 3
};

export function sortWeekDays(data: any[]) {
  return data.sort((a: string, b: string) => {
    return sorterDays[a.toLowerCase()] - sorterDays[b.toLowerCase()];
  });
}

/**
 * Converts time from UTC to a local time based on a given timezone offset.
 *
 * @param {string} time - The time in UTC as a string in the format "HH:MM".
 * @param {number} timezoneOffsetMinutes - The timezone offset in minutes. Positive values are behind of UTC, and negative values are ahead UTC.
 * @returns {string} timeLocal - The converted time in local time, formatted as "HH:MM".
 */
export function convertToLocalTime(
  time: string,
  timezoneOffsetMinutes: number
) {
  // Helper function to add or subtract minutes from a Date object
  function adjustMinutes(date: any, minutes: number) {
    return new Date(date.getTime() + minutes * 60000);
  }
  // Get today's date
  const today = new Date();

  // Construct date strings for today's date with the provided times in UTC
  const timeUTC = new Date(`${today.toISOString().split('T')[0]}T${time}:00Z`);

  // Apply the offset to get local times
  const timeLocal = adjustMinutes(timeUTC, -timezoneOffsetMinutes);

  // Format the local times as HH:mm
  const formatTime = (date: any) => date.toISOString().substr(11, 5);

  return formatTime(timeLocal);
}

export function getPluralMeasure(measure: string) {
  if (measure === 'Unit') {
    return `${measure}(s)`;
  }
  return measure;
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function calculateTimeDifference(
  startTime: string | undefined,
  endTime: string | undefined
): string {
  if (!endTime || !startTime) {
    return 'Invalid dates';
  }

  const end = new Date(endTime);
  const start = new Date(startTime);
  const diff = end.getTime() - start.getTime();

  if (diff < 0) {
    return 'End time is before start time';
  }

  const diffDays = diff / (1000 * 60 * 60 * 24);
  const diffHours = diff / (1000 * 60 * 60);

  if (diffDays >= 30) {
    return '1 month';
  }
  if (diffDays >= 7) {
    return '1 week';
  }
  if (diffDays >= 1) {
    return `${Math.floor(diffDays)} day${Math.floor(diffDays) > 1 ? 's' : ''}`;
  }
  return `${Math.floor(diffHours)} hour${Math.floor(diffHours) > 1 ? 's' : ''}`;
}
