/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  SliderProps,
  SlideProps,
  TextField,
  Typography,
  styled,
  Modal,
  Container
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import RegularInput from '../input/RegularInput';
import Loader from '../shared/Loader';
import { FormLabel } from '../form/parts';
import ToastNotification from '../shared/ToastNotification';
import { toastNotification } from '../../utils/notificationHelper';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 360,
  justifyContent: 'space-between',
  padding: '40px 60px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-12px',
  marginTop: '14px'
}));

const ButtonContainerModal = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '216px'
}));

const DrawerButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

interface Props {
  closeModal: () => void;
  item: any;
  setShowModal: (item: boolean) => void;
}
interface FormValuess {
  widgetsAllowed: number;
}

const CompanyConfigurationsModal: React.FC<Props> =
  function CompanyConfigurationsModal({ closeModal, item, setShowModal }) {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState<FormValuess>({
      widgetsAllowed: item?.widgetsAllowed || 0
    });
    const [updateCompanyApi, setUpdateCompanyApi] = useState<boolean>(false);
    const [toastNotification, setToastNotification] =
      useState<toastNotification>();

    //  ================================================================================= SWR

    const { data, error } = useSWR(
      updateCompanyApi ? [item.id, formValues] : null,
      api.CompanyApi.updateCompanyById
    );

    //  ================================================================================= FUNCTIONS

    //  ================================================================================= USEEFFECTS

    useEffect(() => {
      if (item?.widgetsAllowed) {
        setFormValues(item);
      }
    }, [item]);

    useEffect(() => {
      if (data) {
        setUpdateCompanyApi(false);
        closeModal();
      }
    }, [data]);

    useEffect(() => {
      if (error) {
        setToastNotification({
          text: t('NOTIFICATION.TOAST.generalError'),
          type: 'error'
        });
      }
    }, [error]);

    //  ================================================================================= RENDER

    return (
      <Container sx={{ margin: '40px', width: 'auto' }}>
        <Modal
          open
          onClose={() => {
            setShowModal(false);
          }}
        >
          <ModalContainer>
            <Box pb={2} pt={4}>
              <Typography variant="title">
                {t('COMPANY_SETTINGS_MODAL.title')}
              </Typography>
              <SubTitle>{t('COMPANY_SETTINGS_MODAL.description')}</SubTitle>
            </Box>
            <Box width="90%">
              <FormLabel
                error={false}
                label={t('COMPANY_SETTINGS_MODAL.widgetsAllowed')}
              />
              <RegularInput
                customStyle={{ width: '100%' }}
                onChange={(widgetsAllowed: string) => {
                  setFormValues({ widgetsAllowed: Number(widgetsAllowed) });
                }}
                type="number"
                value={formValues.widgetsAllowed.toString()}
              />
            </Box>
            <ButtonContainerModal>
              <>
                <DrawerButton
                  onClick={() => {
                    setShowModal(false);
                  }}
                  sx={{ borderColor: 'primary.main', height: 46, width: 114 }}
                  variant="outlined"
                >
                  <Typography color="primary.main">
                    {t('MODAL.cancel')}
                  </Typography>
                </DrawerButton>
                <DrawerButton
                  color="primary"
                  onClick={() => {
                    setUpdateCompanyApi(true);
                  }}
                  sx={{ height: 46, width: 114 }}
                  variant="contained"
                >
                  <Typography>{t('GENERAL.confirmButton')}</Typography>
                </DrawerButton>
              </>
            </ButtonContainerModal>
          </ModalContainer>
        </Modal>
        {toastNotification && (
          <ToastNotification
            onClose={() => setToastNotification(undefined)}
            text={toastNotification.text}
            type={toastNotification.type}
          />
        )}
      </Container>
    );
  };

export default CompanyConfigurationsModal;
