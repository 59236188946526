/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  Box,
  Button,
  TableHead,
  TableRow,
  SliderProps,
  TableCell,
  Typography,
  styled,
  Collapse,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ServiceSummary from './ServiceSummary';
import ServiceWindow from './ServiceWindow';
import AssignResources from './AssignResources';
import AssignSupervisor from './AssignSupervisor';
import ServiceTimeDuration from './ServiceTimeDuration';
import { getFrequencyWithDays } from '../../../../utils/general';

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues?: React.Dispatch<React.SetStateAction<any>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ResourceServiceTimeList: React.FC<Props> =
  function ResourceServiceTimeList({
    sendValues,
    saveStepper,
    values,
    setValues,
    setStep
  }) {
    const [open, setOpen] = useState<Array<boolean>>([]);
    const { t } = useTranslation();
    const theme = useTheme();
    const serviceSummary = (index: any) => {
      return ServiceSummary({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const serviceWindow = (index: any) => {
      return ServiceWindow({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const assignResources = (index: any) => {
      return AssignResources({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };
    const assignSupervisor = (index: any) => {
      return AssignSupervisor({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        setValuesST(value: any): void {
          setValues(values);
        },
        values
      });
    };

    const serviceTimeDuration = (index: any) => {
      return ServiceTimeDuration({
        saveStepper,
        serviceIndex: index,
        setStep,
        setValues,
        values
      });
    };

    const auxOpen = (auxIndex: any): boolean => {
      const newOpen = [...open];
      newOpen[auxIndex] = !newOpen[auxIndex];
      setOpen(newOpen);
      return !newOpen[auxIndex];
    };

    const serviceTimeHeader = (index: any) => {
      // const auxOpen = undefined !== open[index];
      if (undefined === open[index]) {
        let auxOpen = open;
        auxOpen[index] = false;
        auxOpen = [...auxOpen];
      }

      return (
        <Box>
          <TableHead
            sx={{
              '.MuiTableHead-root': { boxShadow: 0 },
              backgroundColor: theme.palette.surface[200],
              border: `1px solid ${theme.palette.surface.main}`,
              borderRadius: 1,
              display: 'flex',
              marginTop: '20px',
              width: '100%'
            }}
          >
            {values.serviceTimes[index] && (
              <TableRow
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%'
                }}
              >
                <RowElement onClick={() => {}}>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        justifyContent: 'center',
                        minWidth: 200
                      }}
                    >
                      <Button onClick={() => auxOpen(index)}>
                        <i
                          className="custom-icon icon-select"
                          style={{
                            fontSize: 18,
                            transform: `rotate(${
                              open[index] ? '180deg' : '0deg'
                            })`
                          }}
                        />
                      </Button>
                      <Box>
                        <Typography variant="heading_400">
                          {values &&
                          values.projectType !== 'adhoc' &&
                          values.projectType !== 'Ad-Hoc'
                            ? `${t(
                                'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                              )} #${index + 1}`
                            : `Ad-Hoc ${t(
                                'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                              )} #${index + 1}`}
                        </Typography>
                        <Box>
                          <Typography variant="primary-bold">
                            {values.projectType !== 'Ad-Hoc'
                              ? 'Frecuency: '
                              : 'Service Time Day: '}
                          </Typography>
                          <Typography variant="primary">
                            {values?.serviceTimes &&
                            values.projectType !== 'Ad-Hoc'
                              ? getFrequencyWithDays(
                                  values?.serviceTimes[index],
                                  t
                                )
                              : values?.serviceTimes[index].startDate}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </RowElement>
              </TableRow>
            )}
          </TableHead>
          <Collapse in={open[index]}>
            <Box sx={{ p: 5 }}>
              {serviceSummary(index)}
              {serviceWindow(index)}
              {assignResources(index)}
              {assignSupervisor(index)}
              {serviceTimeDuration(index)}
            </Box>
          </Collapse>
        </Box>
      );
    };

    return (
      <Box>
        {values?.serviceTimes?.length > 0 &&
          values?.serviceTimes.map((serviceTime: any, key: number) => {
            const auxKey = `schedule-serviceTime-${key}`;
            if (
              serviceTime &&
              serviceTime?.id &&
              typeof serviceTime?.id === 'number'
            ) {
              return (
                <React.Fragment key={`${auxKey}`}>
                  {serviceTimeHeader(key)}
                </React.Fragment>
              );
            }

            return null;
          })}
      </Box>
    );
  };

export default ResourceServiceTimeList;
