import { UserDataInsightsApi } from '../../api-client/generated/api';
import { userDashboardParser } from '../../utils/calendarHelper';
import { taskListFormater } from '../timesheet';

const apiCaller = new UserDataInsightsApi();

const getDelayedArrives = async (param: any) => {
  if (!param?.id) {
    return null;
  }

  const response = await apiCaller.userDataInsightsGetDelayedArrives(param);

  return response.data;
};

const getTimesheet = async (param: any) => {
  if (!param?.technicianId) {
    return null;
  }

  const response = await apiCaller.userDataInsightsGetTimesheetReview({
    technicianId: param?.technicianId
  });
  const data = taskListFormater(response, param?.timeFormat);
  return data;
};

const getWeekDashboard = async (params: any) => {
  const { id, startDate, endDate, timeFormat } = params;
  if (!id) {
    const error = {};
    throw error;
  }
  const response: any = await apiCaller.userDataInsightsGetUserDashboard({
    endDate,
    id,
    startDate
  });
  const { data }: any = response;
  const error = {};
  if (!data) {
    throw error;
  }

  return userDashboardParser(
    {
      data: [...data.calendarEvents, ...data.calendarEventsInProgress]
    },
    timeFormat
  );
};

const getWeekStats = async (param: any) => {
  if (!param?.id) {
    return null;
  }
  const { data } = await apiCaller.userDataInsightsGetUserWeekStats(param);
  return data;
};

const getSkillSet = async (param: any) => {
  if (!param?.id) {
    return null;
  }
  const { data } = await apiCaller.userDataInsightsGetSkillsData(param);
  return data;
};

const getProject = async (param: any) => {
  if (!param?.id) {
    return null;
  }
  const { data } = await apiCaller.userDataInsightsGetProjectsByTechnicianId(
    param
  );
  return data as unknown as Array<any>;
};

export default {
  getDelayedArrives,
  getProject,
  getSkillSet,
  getTimesheet,
  getWeekDashboard,
  getWeekStats
};
