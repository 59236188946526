import React, { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { isMobile } from 'react-device-detect';
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Grid,
  TableRow,
  TableCell,
  Paper,
  Button,
  styled,
  useTheme
} from '@mui/material';
import useSWR from 'swr';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import Papa from 'papaparse';
import api from '../../api';
import { themePalette } from '../../theme/themeConfig';
import SidebarReview from './components/SidebarReview';
import TimesheetReviewTable from './components/TimesheetReviewTable';
import TimesheetReportTable from './components/TimesheetReportTable';
import { decimalsToHours, formatSumTime } from '../../utils/calendarHelper';
import { getCurrency } from '../../utils/general';
import ViewLoader from '../../components/shared/ViewLoader';
import useNavigationStore from '../../store/zustand/navigation';
import useAuthStore from '../../store/zustand/auth';

export const TitlesRow = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'wrap',
  gap: theme.spacing(2),
  justifyContent: 'left',
  marginBottom: theme.spacing(6),
  width: '100%'
}));

const Timesheet: React.FC = function Timesheet() {
  const [step, setStep] = useState<number>(0);
  const [getTaskToReview, setGetTaskToReview] = useState(false);
  const [taskToReviewData, setTaskToReviewData] = useState<any>([]);
  const [getReportData, setGetReportData] = useState(false);
  const [userIndexSelect, setUserIndexSelect] = useState<number>(0);
  const [reportData, setReportData] = useState<any>({});
  const [fireUpdateTaskDecision, setFireUpdateTaskDecision] = useState(false);
  const [reportByDate, setReportByDate] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const userState = useAuthStore((state) => state);

  const [reportRanges, setReportRanges] = useState<any>({
    fromDate: null,
    fromDateFormated: '',
    toDate: null,
    toDateFormated: ''
  });

  const [updateDecisionData, setUpdateDecisionData] = useState({
    actualTotal: 0,
    approvalStatus: '',
    taskIterationId: -1,
    type: ''
  });

  const {
    data: taskToReviewDataResult,
    error: getTaskToReviewDataError,
    mutate
  } = useSWR(
    getTaskToReview
      ? {
          timeFormat: userState?.timeFormat,
          url: 'api/timesheet/taskToReviewForOperator'
        }
      : null,
    api.TimesheetApi.getTechnicianTaskToReviewForOperator
  );

  const {
    data: reportDataResult,
    error: getReportDataError,
    mutate: mutateReport
  } = useSWR(
    getReportData
      ? {
          fromDate: reportRanges.fromDateFormated,
          timeFormat: userState?.timeFormat,
          toDate: reportRanges.toDateFormated
        }
      : null,
    api.TimesheetApi.getTechnicianTimesheetTasks
  );

  //= ================= FUNCTIONS =============================

  const handleDateChange = (newDate: any, opt: number) => {
    if (newDate !== null && `${newDate?.$y}` !== 'NaN') {
      switch (opt) {
        case 5: {
          setReportRanges({
            ...reportRanges,
            fromDate: newDate,
            fromDateFormated: newDate
              ? `${newDate.format('YYYY-MM-DD')}T00:00:00`
              : ''
          });
          break;
        }
        case 6: {
          setReportRanges({
            ...reportRanges,
            toDate: newDate,
            toDateFormated: newDate
              ? `${newDate.format('YYYY-MM-DD')}T23:59:59`
              : ''
          });
          break;
        }
        default:
          break;
      }
    }
  };

  const handleError = () => {
    setConfirmModal(
      () => {
        console.log('handleError');
      },
      `${t('GENERAL.confirmButton')}`,
      () => {},
      ``,
      <Box display="flex" flexDirection="column" pl={8} pr={8}>
        <Box p={2}>
          <i
            className="custom-icon icon-info"
            style={{
              color: theme.palette.error.main,
              fontSize: 48
            }}
          />
        </Box>
        <Typography variant="primary">{`${t('TIMESHEET.error')}`}</Typography>
      </Box>
    );
  };

  //= ================= USE_EFFECTS =============================

  useEffect(() => {
    if (taskToReviewDataResult && !getTaskToReviewDataError) {
      setIsLoading(false);
      setGetTaskToReview(false);
      setTaskToReviewData(taskToReviewDataResult);
    }
  }, [taskToReviewDataResult]);

  useEffect(() => {
    if (getTaskToReviewDataError) {
      setIsLoading(false);
      handleError();
    }
  }, [getTaskToReviewDataError]);

  useEffect(() => {
    if (reportDataResult && !getReportDataError) {
      setIsLoading(false);
      setGetReportData(false);
      setReportData(reportDataResult);
    }
  }, [reportDataResult]);

  useEffect(() => {
    if (getReportDataError) {
      setIsLoading(false);
      handleError();
    }
  }, [getReportDataError]);

  useEffect(() => {
    if (reportRanges.fromDate && reportRanges.toDate) {
      setIsLoading(true);
      setGetReportData(true);
    } else {
      setGetReportData(false);
    }
  }, [reportRanges]);

  useEffect(() => {
    if (
      updateDecisionData?.approvalStatus !== '' &&
      updateDecisionData?.taskIterationId > 0
    ) {
      setIsLoading(true);
      setFireUpdateTaskDecision(true);
    }
  }, [updateDecisionData]);

  useEffect(() => {
    setIsLoading(true);
    setGetTaskToReview(true);
  }, []);

  useEffect(() => {
    const todayDate = new Date();
    const oneMonthAgo = new Date(
      new Date().setMonth(new Date().getMonth() - 1)
    );
    setReportRanges({
      ...reportRanges,
      fromDate: oneMonthAgo,
      fromDateFormated: oneMonthAgo ? `${oneMonthAgo.toJSON()}` : '',
      toDate: todayDate,
      toDateFormated: todayDate ? `${todayDate.toJSON()}` : ''
    });
  }, []);

  const downloadCSV = () => {
    if (reportData.length === 0) {
      return;
    }
    const csv = Papa?.unparse({
      data: reportData?.flatMap((item: any) => {
        return {
          hourlyRate: getCurrency(item.dataList[0].laborHourCost),
          technician: item.technician,
          technicianPaymentTotal: getCurrency(item.technicianPaymentTotal),
          totalHoursScheduled: decimalsToHours(item.totalHoursScheduled),
          totalHoursWorked: decimalsToHours(item.totalHoursWorked)
        };
      }),
      fields: [
        'technician',
        'hourlyRate',
        'technicianPaymentTotal',
        'totalHoursWorked',
        'totalHoursScheduled'
      ]
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'CSV Export File';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Container
      id="calendar-page-container"
      key="timesheet-container"
      sx={{
        justifyContent: 'left',
        margin: '16px 40px 16px 40px',
        padding: '1rem',
        width: 'auto'
      }}
    >
      {/* Title */}
      <Box
        key="timesheet-box-main-title-container"
        sx={{
          alignItems: 'left',
          borderRadius: '0.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          justifyContent: 'center',
          marginTop: '16px',
          minHeight: '2rem',
          padding: '1rem 0rem',
          width: '88%'
        }}
      >
        <Typography
          color="primary.400"
          fontSize={32}
          sx={{
            color: themePalette.TITLE,
            fontFamily: 'Nunito, sans-serif',
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '36px',
            marginTop: '8px',
            width: '50%'
          }}
          variant="heading_300"
        >
          {`${t('TIMESHEET.timesheetTitle')}`}
        </Typography>
      </Box>
      <Box key="timesheet-box-main-tabs-container">
        <Tabs
          aria-label="basic tabs example"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            if (newValue === 0) {
              mutate(null, false);
              setGetTaskToReview(true);
            }
            if (newValue === 1) {
              mutateReport(null, false);
              setGetReportData(true);
            }
            setStep(newValue);
          }}
          value={step}
        >
          <Tab label="Review" />
          <Tab label="Report" />
        </Tabs>
        <Box
          mt={2}
          style={{
            backgroundColor: 'rgb(255,255,255)',
            marginLeft: '-32px',
            marginRight: '-32px'
          }}
        >
          {/* Review */}
          {step === 0 && (
            <Box key="timeshee-tab-2" sx={{ padding: '2rem 0rem' }}>
              <Box display="block" key="timesheet-box-review-title" p={1}>
                <Typography
                  sx={{ fontSize: '24px', fontWeight: '700', width: 'auto' }}
                  variant="primary"
                >
                  {`${t('TIMESHEET.review')}`}
                </Typography>
              </Box>
              {taskToReviewData?.length > 0 ? (
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <Paper>
                      {taskToReviewData[userIndexSelect] && (
                        <TimesheetReviewTable
                          isTechnician
                          data={taskToReviewData[userIndexSelect] || []}
                          isLoading={isLoading}
                          setUpdateDecisionData={setUpdateDecisionData}
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="center" justifyContent="center">
                  {isLoading ? (
                    <ViewLoader />
                  ) : (
                    <Typography>{`${t('GENERAL.noData')}`}</Typography>
                  )}
                </Grid>
              )}
            </Box>
          )}

          {/* Report */}
          {step === 1 && (
            <Box key="timeshee-tab-2" sx={{ padding: '2rem 1rem' }}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                key="timesheet-box-review-title"
                p={1}
                sx={{
                  flexFlow: 'wrap'
                }}
              >
                <Typography
                  sx={{ fontSize: '24px', fontWeight: '700', width: 'auto' }}
                  variant="primary"
                >
                  {`${t('TIMESHEET.report')}`}
                </Typography>
                <Box marginBottom="20px" marginTop="20px">
                  <Button
                    onClick={() => downloadCSV()}
                    sx={{
                      marginRight: '10px',
                      marginTop: '10px',
                      width: '180px'
                    }}
                    variant="primary"
                  >
                    {`${t('TIMESHEET.exportCSV')}`}
                  </Button>
                </Box>
              </Box>
              <Box
                display="ruby-text"
                flexDirection="column"
                key="timesheet-box-data-range-container"
                sx={{ padding: '1rem 0rem', textAlign: 'left' }}
              >
                <Box display="flex" key="timesheet-box-data-range1">
                  <Box>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      key="timeSheet-LocalizationProvider-range1-fromDate"
                      sx={{
                        maxWidth: '200px !important'
                      }}
                    >
                      <DatePicker
                        label="Start Date"
                        onChange={(fromDate: any) => {
                          handleDateChange(fromDate, 5);
                        }}
                        renderInput={(params) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            sx={{
                              backgroundColor: 'transparent !important',
                              marginBottom: '0.75rem !important'
                            }}
                          />
                        )}
                        value={reportRanges.fromDate}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      key="timeSheet-LocalizationProvider-range1-toDate"
                      sx={{
                        '.MuiInputBase-formControl': {
                          maxWidth: '200px !important'
                        },
                        marginBottom: '0.75rem',
                        maxWidth: '200px !important'
                      }}
                    >
                      <DatePicker
                        label="End Date"
                        minDate={dayjs(reportRanges.fromDate) || undefined}
                        onChange={(toDate) => {
                          handleDateChange(toDate, 6);
                        }}
                        renderInput={(params) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            sx={{ backgroundColor: 'transparent !important' }}
                          />
                        )}
                        value={reportRanges.toDate}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
              {reportData?.length > 0 ? (
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <Paper>
                      <TimesheetReportTable
                        byDate
                        isTechnician
                        data={reportData || []}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              ) : (
                <Grid container alignItems="center" justifyContent="center">
                  {isLoading ? (
                    <ViewLoader />
                  ) : (
                    <Typography>{`${t('GENERAL.noData')}`}</Typography>
                  )}
                </Grid>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Timesheet;
