import React, { useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  styled,
  SlideProps,
  Typography,
  Stack,
  Chip,
  Box,
  Tooltip,
  Button
} from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranslation } from 'react-i18next';
import { MapOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  capitalizeString,
  checkStyle,
  chipDaysStyle,
  formatDate,
  getComparator,
  getCurrency,
  getPercentageOfDaysCompleted,
  rolesStyle,
  skillStyle,
  stableSort,
  statusStyle
} from '../../utils/general';
import { Order } from '../../types';
import ArrayChips from '../list/ArrayChips';
import ViewLoader from './ViewLoader';

const HeadColumn = styled(TableCell)<SlideProps>(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold'
}));

const RowElement = styled(TableCell)<SlideProps>(({ theme }) => ({
  borderBottom: 'none',
  color: theme.palette.secondary[400],
  fontSize: '14px',
  fontWeight: '400',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

const RowContainer = styled(TableRow)<SlideProps>(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.mainText?.[100]
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#FCFCFC'
  },
  alignItems: 'center',
  borderTop: '1px solid #DDDDDD',
  cursor: 'pointer',
  display: 'flex',
  height: '56px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

interface headIconProps {
  headcell: string;
  order: string;
  orderby: string;
}

const HeadIconColumnSort = styled(ExpandMoreOutlinedIcon)<headIconProps>(
  ({ theme, orderby, headcell, order }) => ({
    color: theme.palette.mainText?.[500],
    display: 'flex !important',
    marginLeft: '10px',
    transform:
      orderby === headcell && order === 'desc'
        ? 'rotate(0deg)'
        : 'rotate(180deg)'
  })
);

const inTableButtons = [
  'delete',
  'resend',
  'edit',
  'unblock',
  'onboarding',
  'show',
  'insights',
  'activate',
  'route',
  'visits'
];
const hideHeader = ['statusIcon'];
const iconStatus: any = {
  approved: '',
  awaiting: 'clock',
  block: 'lock',
  created: 'help',
  highPriority: 'doubleArrow',
  lock: 'lock',
  lowPriority: 'clock',
  mediumPriority: 'simpleArrow',
  notRequired: 'cancelCircle',
  rejected: 'warning',
  retrieved: 'cancelCircle',
  unblock: 'unlock',
  unlock: 'unlock',
  warning: 'clock'
};
const iconColor: any = {
  approved: '',
  awaiting: 'warning.main',
  completed: 'success.main',
  created: 'info.main',
  highPriority: 'error.main',
  lowPriority: 'info.main',
  mediumPriority: 'warning.main',
  notRequired: 'surface.main',
  overdue: 'error.main',
  rejected: 'error.main',
  retrieved: 'error.main',
  warning: 'warning.main'
};

const getTableWith = (columns: number, index: number, fullSize: boolean) => {
  if (fullSize) {
    return '100%';
  }
  if (columns <= 4 && index === 0) {
    return '16%';
  }
  if (columns <= 4) {
    return '24%';
  }
  return '100%';
};

const getTableWithButton = (buttonString: string) => {
  if (buttonString === 'resend') {
    return '40%';
  }
  if (buttonString === 'delete') {
    return '24%';
  }
  if (buttonString === 'insights') {
    return '24%';
  }
  if (buttonString === 'activate') {
    return '24%';
  }
  if (buttonString === 'route') {
    return '24%';
  }
  if (buttonString === 'visits') {
    return '50%';
  }
  return '20%';
};
interface EnhancedTableProps {
  fullSize: boolean;
  headValues: Array<string>;
  // eslint-disable-next-line no-unused-vars
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  t: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headValues, t, fullSize } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        '.MuiTableHead-root': { boxShadow: 0 },
        backgroundColor: '#F5F5F5'
      }}
    >
      <TableRow
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '56px'
        }}
      >
        {headValues.map((headCell: string, index: number) => (
          <HeadColumn
            key={`${headCell}-${index + 1}`}
            sortDirection={orderBy === headCell ? order : false}
            sx={{ justifyContent: 'left' }}
            width={
              inTableButtons.includes(headCell)
                ? getTableWithButton(headCell)
                : getTableWith(headValues.length, index, fullSize)
            }
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
              sx={{
                '.MuiSvgIcon-root': { display: 'none' },
                color: 'mainText.500',
                fontSize: '16px'
              }}
            >
              {!inTableButtons.includes(headCell) && (
                <>
                  {headCell === ''
                    ? ''
                    : capitalizeString(`${t(`LISTING_PAGES.${headCell}`)}`)}
                  {headCell !== '' && (
                    <HeadIconColumnSort
                      headcell={headCell}
                      order={order}
                      orderby={orderBy}
                    />
                  )}
                </>
              )}
            </TableSortLabel>
          </HeadColumn>
        ))}
      </TableRow>
    </TableHead>
  );
}

const getElement = (row: any, key: string, lastElement: boolean) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  switch (key) {
    case 'taskMaterials': {
      if (!row[key] || (row[key] && row[key].length === 0)) {
        return '';
      }
      const materialArray = row[key].map((item: any) => item.material);
      const toReturn = materialArray.map((item: any) => item?.name);
      return toReturn.toString().replaceAll(',', ', ');
    }
    case 'amount': {
      return <Typography variant="primary">{getCurrency(row[key])}</Typography>;
    }
    case 'dueDate':
    case 'sendDate': {
      return <Typography variant="primary">{formatDate(row[key])}</Typography>;
    }
    case 'materials': {
      if (!row?.materials || (row?.materials && row.materials.length === 0)) {
        return [];
      }
      const toReturn = row.materials.map((item: any) => {
        return {
          cost: parseFloat(item?.cost || 0),
          materialId: item?.id,
          name: item?.name
        };
      });
      return (
        <ArrayChips
          colorFont="#5C20AC"
          elementsArray={toReturn}
          style={checkStyle}
        />
      );
    }
    case 'checklist': {
      if (
        !row.taskChecklist ||
        (row.taskChecklist && row.taskChecklist.length === 0)
      ) {
        return '';
      }
      const toReturn = row.taskChecklist.map((item: any) => item?.name);
      return (
        <ArrayChips
          colorFont="#5C20AC"
          elementsArray={toReturn}
          style={checkStyle}
        />
      );
    }
    case 'list': {
      if (
        !row.list ||
        (row.list && row.list.length === 0)
      ) {
        return '';
      }
      const toReturn = row.list.map((item: any) => item);
      return (
        <ArrayChips
          colorFont="#5C20AC"
          elementsArray={toReturn}
          style={checkStyle}
        />
      );
    }
    case 'roles': {
      if (!row[key] || (row[key] && row[key].length === 0)) {
        return '';
      }
      const toReturn = row[key].map((item: { name: string }) => item?.name);
      return (
        <ArrayChips
          colorFont="#0F5C56"
          elementsArray={toReturn}
          style={rolesStyle}
        />
      );
    }
    case 'route': {
      return (
        <Tooltip title={t('TOOLTIP.INSIGHTS.map')}>
          <Button
            onClick={() => {
              navigate(
                `/app/resources/insights-routes/${row?.technician?.id}`,
                {
                  state: {
                    index: row?.index || 0,
                    tasks: row?.totalItems || [row]
                  }
                }
              );
            }}
            variant="table"
          >
            <MapOutlined />
          </Button>
        </Tooltip>
      );
    }
    case 'status': {
      if (!row[key]) {
        return '';
      }

      const toReturn = row[key];
      const progress = getPercentageOfDaysCompleted(
        row?.startDate || row?.leadProjectStartDate,
        row?.endDate || row?.leadProjectEndDate,
        row?.status
      );
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'start',
            padding: '2px',
            width: '100%',
            ...statusStyle(toReturn),
            backgroundColor: 'transparent',
            position: 'relative'
          }}
        >
          {progress && (
            <Box
              sx={{
                backgroundColor: statusStyle(toReturn).backgroundColor,
                borderRadius: 24,
                display: 'flex',
                height: '24px',
                justifyContent: 'center',
                width: `${progress}%`
              }}
            />
          )}
          <Typography
            sx={{
              paddingBottom: 1,
              position: 'absolute',
              textAlign: 'center',
              width: '100%'
            }}
            variant="button_100"
          >
            <i className={`custom-icon icon-${toReturn}`} />
            {toReturn}
          </Typography>
        </Box>
      );
    }
    case 'assignmentStatus': {
      if (!row.status && !row?.assignmentStatus) {
        return '';
      }
      const tooltip = `${row?.tooltip}`;
      const { icon, priorityIcon } = row;
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: iconStatus[icon] ? 1 : 0,
            width: '100%'
          }}
        >
          <Tooltip arrow title={tooltip}>
            <Typography
              color={iconColor[priorityIcon]}
              sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}
              variant="button_100"
            >
              <i
                className={`custom-icon icon-${iconStatus[priorityIcon]}`}
                style={{ fontSize: 18 }}
              />
              {row?.priorityText}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    case 'statusIcon': {
      if (!row.status && !row?.assignmentStatus) {
        return '';
      }
      const toReturn = row?.assignmentStatus || row.status;
      const { icon } = row;
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: iconStatus[icon] ? 1 : 0,
            width: '100%'
          }}
        >
          <Stack>
            <Chip
              label={<Typography variant="button_100">{toReturn}</Typography>}
              style={statusStyle(toReturn)}
            />
          </Stack>
          <Tooltip arrow title={row?.tooltip || icon}>
            <Typography
              color={iconColor[icon]}
              sx={{ display: 'flex', justifyContent: 'center' }}
              variant="button_100"
            >
              <i
                className={`custom-icon icon-${iconStatus[icon]}`}
                style={{ fontSize: 18 }}
              />
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    case 'tasks':
    case 'skills':
    case 'categories': {
      if (!row[key] || (row[key] && row[key].length === 0)) {
        return '';
      }
      const toReturn = row[key].map((item: { name: string }) => item?.name);
      return (
        <ArrayChips
          colorFont="primary.400"
          elementsArray={toReturn}
          style={skillStyle}
        />
      );
    }
    case 'created':
      return (
        row?.createdAt && (
          <Typography variant="primary">{`${formatDate(
            row?.createdAt
          )}`}</Typography>
        )
      );
    case 'item':
      return (
        <Typography variant="primary">{`${row?.material?.name}`}</Typography>
      );
    case 'priceUnit':
      return `${getCurrency(row.cost)}`;
    case 'priceSquareFeet':
      return `${getCurrency(
        parseFloat(row.cost) / parseFloat(row.task.measurePerHour)
      )}`;
    case 'totalCostMaterial':
      if (row?.task?.measure?.name === 'Unit') {
        return `${getCurrency(
          parseFloat(row.task.measurePerHour || 0) * row.task.quantity ||
            0 * parseFloat(row.cost || 0)
        )}`;
      }
      return `${getCurrency(
        (parseFloat(row.task.measurePerHour || 0) / row.task.quantity || 0) *
          parseFloat(row.cost || 0)
      )}`;
    case 'quantity':
    case 'quantitySquareFeet':
    case 'quantityUnits':
      return row.task.quantity;
    case 'rate':
      if (lastElement) {
        return <Typography variant="primary-bold">{`${row[key]}`}</Typography>;
      }
      return `${getCurrency(row[key])}`;
    case 'total':
      if (lastElement) {
        return (
          <Typography variant="primary-bold">{`${getCurrency(
            row[key]
          )}`}</Typography>
        );
      }
      return `${getCurrency(row[key])}`;
    case 'laborCost':
    case 'other':
    case 'payrollTax':
      if (lastElement) {
        return (
          <Typography variant="primary-bold">{`${getCurrency(
            row[key]
          )}`}</Typography>
        );
      }
      return `${getCurrency(row[key])}`;
    case 'requestedAvailableDays': {
      if (
        !row.requestedAvailableDays ||
        (row.requestedAvailableDays && row.requestedAvailableDays.length === 0)
      ) {
        return '';
      }
      const toReturn = row.requestedAvailableDays.map((item: string) => item);
      return (
        <Stack direction="row" spacing={1}>
          {toReturn?.map((ele: string) => (
            <Tooltip arrow title={ele}>
              <Chip
                label={
                  <Typography color="primary.400" variant="button_100">
                    {ele.charAt(0)}
                  </Typography>
                }
                style={chipDaysStyle}
              />
            </Tooltip>
          ))}
        </Stack>
      );
    }
    default:
      return row[key]?.name ? row[key].name : row[key];
  }
};

type props = {
  action: React.Dispatch<React.SetStateAction<string>>;
  fullSize?: boolean;
  // eslint-disable-next-line react/require-default-props
  handleOpenDraw?: any;
  headKeys: Array<string>;
  hideTableHeader?: boolean;
  isItemLoaded: (index: any) => boolean;
  itemCount: number;
  loadMoreItems: any;
  // eslint-disable-next-line react/require-default-props
  orderByManual?: string;
  // eslint-disable-next-line react/require-default-props
  orderManual?: string;
  redirectRow?: boolean;
  valuesArray: { data: any[] };
};

interface rowprops {
  action: any;
  fullSize: boolean;
  handleOpenDraw?: any;
  headKeys: Array<string>;
  index: number;
  order: Order;
  orderBy: string;
  redirectRow?: boolean;
  rows: any;
  style: any;
}

const RowElementComponent: React.FC<rowprops> = function RowElementComponent({
  index,
  handleOpenDraw,
  headKeys,
  action,
  style,
  redirectRow,
  rows,
  order,
  orderBy,
  fullSize
}) {
  const row: any = stableSort(rows, getComparator(order, orderBy));
  const item = row[index];
  const lastElement = index + 1 === rows.length;
  return row[index] ? (
    <RowContainer
      hover
      key={`${item.id}`}
      onClick={() => {
        if (redirectRow) {
          action(item);
        }
      }}
      style={style}
      sx={{
        textOverflow: inTableButtons.includes(item) ? 'unset' : 'ellipsis'
      }}
    >
      <>
        {headKeys.map((headKey: string, index: number) => {
          return (
            <RowElement
              align="left"
              key={`${index + 1}-${headKey}${item[headKey]}-${item.id}`}
              onClick={
                !inTableButtons.includes(headKey) ? handleOpenDraw : () => {}
              }
              sx={{
                textOverflow: inTableButtons.includes(headKey)
                  ? 'unset'
                  : 'ellipsis'
              }}
              width={
                inTableButtons.includes(headKey)
                  ? getTableWithButton(headKey)
                  : getTableWith(headKeys.length, index, fullSize)
              }
            >
              <Typography variant="body_200">
                {getElement(item, headKey, lastElement)}
              </Typography>
            </RowElement>
          );
        })}
      </>
    </RowContainer>
  ) : null;
};

RowElementComponent.defaultProps = {
  handleOpenDraw: undefined,
  redirectRow: true
};

const TableComponent: React.FC<props> = function TableComponent({
  valuesArray,
  action,
  headKeys,
  isItemLoaded,
  itemCount,
  loadMoreItems,
  handleOpenDraw = () => {},
  hideTableHeader,
  orderByManual = 'name',
  redirectRow,
  orderManual = 'asc',
  fullSize = false
}) {
  const [order, setOrder] = React.useState<Order>(
    orderManual === 'asc' ? 'asc' : 'desc'
  );
  const [orderBy, setOrderBy] = React.useState<string>(orderByManual || 'name');
  const rows = valuesArray.data;
  const { t } = useTranslation();
  const calculateWidth = headKeys?.length < 4 ? '940px' : '88%';

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    if (property !== orderBy && !isAsc) {
      setOrderBy(property);
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
    }
  };

  interface rowListProp {
    index: number;
    style: any;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function RowListItem({ index, style }: rowListProp) {
    return (
      <RowElementComponent
        action={action}
        fullSize={fullSize}
        handleOpenDraw={handleOpenDraw}
        headKeys={headKeys}
        index={index}
        order={order}
        orderBy={orderBy}
        redirectRow={redirectRow}
        rows={rows}
        style={style}
      />
    );
  }

  const handleScroll = useCallback(
    (event: any) => {
      const { target } = event;
      if (451 - (event?.scrollOffset || 0) <= 0) {
        loadMoreItems();
      }
    },
    [loadMoreItems]
  );

  return valuesArray !== undefined ? (
    <TableContainer
      className="scrollbar"
      sx={{
        border: '1px solid #DDDDDD',
        height: '100%',
        width: fullSize ? '100%' : calculateWidth
      }}
    >
      <Table>
        {!hideTableHeader && (
          <EnhancedTableHead
            fullSize={fullSize}
            headValues={headKeys}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            t={t}
          />
        )}
        <TableBody sx={{ backgroundColor: 'white' }}>
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }: any) => (
              <List
                height={rows && rows.length < 9 ? rows.length * 50 : 450}
                itemCount={rows?.length}
                itemSize={50}
                onItemsRendered={onItemsRendered}
                onScroll={handleScroll}
                ref={ref}
                width="100%"
              >
                {RowListItem}
              </List>
            )}
          </InfiniteLoader>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <ViewLoader />
  );
};

TableComponent.defaultProps = {
  fullSize: false,
  handleOpenDraw: undefined,
  hideTableHeader: undefined,
  redirectRow: true
};

export default TableComponent;
