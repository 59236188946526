/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import TextNumber from './components/TextNumber';
import CardHeader from './CardHeader';
import ServiceTable from './ServiceTable';
import { getWorkedTimeAverageTime } from '../../../utils/calculatorHelper';

function CustomTabs(props: any) {
  return (
    <Tabs
      style={{ marginleft: '-93px !important' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{
        '.MuiTabs-indicator': {
          display: 'none'
        }
      }}
    />
  );
}

export interface IServiceByService {
  calendarEvenState?: string;
  detailInfo: any;
  detailProjectId: any;
  mutateAction: () => void;
  onlyTask: boolean;
  projectType: string;
  service: any;
  servicesInfo: any;
  setOnlyTask: React.Dispatch<React.SetStateAction<any>>;
  type: string;
  typeView: string;
}

const ServiceByService: React.FC<IServiceByService> =
  function ServiceByService({
    service,
    servicesInfo,
    type,
    typeView,
    detailProjectId,
    onlyTask,
    setOnlyTask,
    detailInfo,
    projectType,
    mutateAction,
    calendarEvenState
  }) {
    const [isOpen, setIsOpen] = useState(type === 'serviceTime' ? true : false);
    const [step, setStep] = useState<number>(0);
    const [auxTime, setAuxTime] = useState<any>();

    useEffect(() => {
      let auxTimeSum = 0;
      let sumWorkedTime = 0;

      if (service) {
        service?.tasks?.forEach((task: any) => {
          if (task?.technicians?.length > 0) {
            auxTimeSum +=
              (task?.totalTimeInHours || 1) / (task?.technicians?.length || 1);
          } else {
            auxTimeSum +=
              (task?.totalTimeInHours || 1) / (task?.minimumResources || 1);
          }

          if (task?.taskIteration?.length > 0) {
            sumWorkedTime += getWorkedTimeAverageTime(task?.taskIteration);
          }
        });

        setAuxTime({
          estimated: auxTimeSum,
          projectType,
          total: sumWorkedTime
        });
      }
    }, [service]);

    return (
      <Box
        style={{
          borderTop: 'solid #CBCCD3 1px'
        }}
      >
        <CardHeader
          componentType="estimated"
          isOpen={isOpen}
          projectType={projectType}
          propsTime={auxTime}
          setIsOpen={setIsOpen}
          title={onlyTask ? service?.name : service?.name}
        />
        {isOpen && (
          <Box>
            <Box
              style={{
                backgroundColor: '#F7F6F9',
                borderTop: 'solid #CBCCD3 1px',
                display: 'flex',
                flexDirection: 'row',
                padding: '10px'
              }}
            >
              <CustomTabs
                aria-label="basic tabs example"
                indicatorColor="transparent"
                onChange={(_: React.SyntheticEvent, newValue: number) => {
                  setStep(newValue);
                }}
                style={{
                  backgroundColor: '#F7F6F9',
                  height: '100hv'
                }}
                value={step}
              >
                {type === 'serviceTime' && service && (
                  <Tab
                    label={
                      <TextNumber
                        isActive={step}
                        number={service.tasks.length.toString()}
                        position={0}
                        text="Task"
                      />
                    }
                  />
                )}
                {type === 'serviceSetup' && service && (
                  <Tab
                    label={
                      <TextNumber
                        isActive={step}
                        number={service.tasks.length.toString()}
                        position={0}
                        text="Task"
                      />
                    }
                  />
                )}
              </CustomTabs>
            </Box>

            <Box>
              {onlyTask === false && service.tasks.length > 0 ? (
                <ServiceTable
                  calendarEvenState={calendarEvenState || undefined}
                  dashboard={
                    type === 'serviceSetup' || type === 'serviceTime'
                      ? service.tasks
                      : []
                  }
                  mutateAction={mutateAction}
                  onlyTask={onlyTask}
                  serviceInfo={{
                    ...detailInfo,
                    timezone:
                      detailProjectId?.proposal?.lead?.address?.timezone ||
                      detailInfo?.timezone
                  }}
                  typeView={
                    type === 'serviceSetup' ? 'serviceSetup' : 'serviceTime'
                  }
                />
              ) : (
                <ServiceTable
                  calendarEvenState={calendarEvenState || undefined}
                  dashboard={
                    type === 'serviceSetup' || type === 'serviceTime'
                      ? service.tasks
                      : []
                  }
                  mutateAction={mutateAction}
                  onlyTask={onlyTask}
                  serviceInfo={{
                    ...detailProjectId?.proposal?.lead?.serviceTimes[0],
                    timezone:
                      detailProjectId?.proposal?.lead?.address?.timezone ||
                      detailInfo?.timezone
                  }}
                  typeView={
                    type === 'serviceSetup' ? 'serviceSetup' : 'serviceTime'
                  }
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };
ServiceByService.defaultProps = {
  calendarEvenState: undefined
};
export default ServiceByService;
