/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  SliderProps,
  TextField,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import AutoCompleteInput from '../../../components/input/AutoCompleteInput';
import { capitalizeString } from '../../../utils/general';
import { FormLabel } from '../../../components/form/parts';
import api from '../../../api';
import RegularInput from '../../../components/input/RegularInput';
import CustomRadio from '../../../components/form/CustomRadio';
import Dropzone from '../../../components/input/Dropzone';
import useNavigationStore from '../../../store/zustand/navigation';
import ManualorServiceCatalogModal from '../../../components/modals/ManualorServiceCatalogModal';
import Loader from '../../../components/shared/Loader';

const BoxCentered = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
}));

interface Props {
  curentLeadId: any;
  saveStepper: ({ next }: any) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const GeneralInformation: React.FC<Props> = function GeneralInformation({
  curentLeadId = null,
  values,
  setValues,
  saveStepper,
  sendValues
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: params } = useLocation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const [clients, setClients] = useState<Array<any>>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [askClients, setAskClients] = useState<boolean>(false);
  // const [askContacts, setAskContacts] = useState<boolean>(false);
  // const [askAddresses, setAskAddresses] = useState<boolean>(false);
  const [showManualPricingModal, setShowManualPricingModal] =
    useState<boolean>(false);
  const [validData, setValidData] = useState<boolean>(true);
  const theme = useTheme();
  const multiple = true;
  // const { getClients, getContacts, getAddresses } = api.ClientApi;
  const { getClients } = api.ClientApi;

  //   SWR ==========================================================================================

  const { data: clientsData, error: clientsError } = useSWR(
    askClients ? { url: 'get/allClients' } : null,
    getClients
  );

  //   FUNCTIONS ==========================================================================================

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 2 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMsg}
        </Typography>
      </Container>
    );

  const autoCompleteCustom = (
    key: string,
    disabled: boolean,
    options: any[],
    localError: boolean,
    placeHolder: string,
    value: any
  ) => {
    const helper =
      (options &&
        options.length &&
        value?.id &&
        options.find((option) => option.id === value?.id)) ||
      null;
    return (
      <AutoCompleteInput
        closeOnSelect
        disabled={disabled}
        onChange={(event: any, newValue: any | null) => {
          if (newValue?.createValue) {
            navigate('/app/clients/new', {
              state: { ...values, lastPage: '/app/leads/new' }
            });
          } else {
            setValues({ ...values, [key]: newValue });
          }
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            error={localError}
            placeholder={placeHolder}
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                borderStyle: 'hidden'
              },
              borderStyle: 'hidden',
              fontSize: '15px'
            }}
            {...params}
          />
        )}
        value={helper}
      />
    );
  };

  const getClientInput = () => {
    let showAutoComplete = false;
    if (params?.clientCreated) {
      if (values.clientReady && values?.client?.id && clients?.length > 0) {
        showAutoComplete = true;
      }
    } else if (values?.client?.id || clients?.length > 0) {
      showAutoComplete = true;
    }
    if (showAutoComplete) {
      return autoCompleteCustom(
        'client',
        clients?.length === 0 || curentLeadId,
        clients?.length > 0 ? clients : [values.client],
        !values.client && error,
        capitalizeString(
          `${t('LEAD.CREATE.GENERAL_INFORMATION.select')} ${t(
            'LEAD.CREATE.GENERAL_INFORMATION.client'
          )}`
        ),
        values.client
      );
    }
    return (
      <RegularInput
        disabled
        customStyle={{ width: '100%' }}
        placeholder={
          clientsData
            ? `${t(
                'GENERAL.loading',
                t('LEAD.CREATE.GENERAL_INFORMATION.client')
              )}`
            : `${t('LEAD.CREATE.GENERAL_INFORMATION.addClientFirst')}`
        }
      />
    );
  };

  function clientAndContact() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ width: '49%' }}>
          <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.client')} />
          {getClientInput()}
        </Box>
        <Box sx={{ width: '49%' }}>
          <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.contact')} />
          {values?.client ? (
            autoCompleteCustom(
              'contact',
              !values.client?.contacts || curentLeadId,
              values.client?.contacts,
              !values.client?.contacts && error,
              values.client?.contacts?.length > 0
                ? capitalizeString(
                    `${t('LEAD.CREATE.GENERAL_INFORMATION.select')} ${t(
                      'LEAD.CREATE.GENERAL_INFORMATION.contact'
                    )}`
                  )
                : t('LEAD.CREATE.GENERAL_INFORMATION.dontHave', [
                    t('LEAD.CREATE.GENERAL_INFORMATION.contact')
                  ]),
              values.contact
            )
          ) : (
            <RegularInput
              disabled
              customStyle={{ width: '100%' }}
              placeholder={`${t(
                'LEAD.CREATE.GENERAL_INFORMATION.noClientSelected'
              )}`}
            />
          )}
        </Box>
      </Box>
    );
  }

  const projectNameAndAddress = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ width: '49%' }}>
          <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.projectName')} />
          <RegularInput
            customStyle={{ width: '100%' }}
            disabled={values?.readOnly}
            onChange={(name) => setValues({ ...values, name })}
            value={values.name}
          />
        </Box>
        <Box sx={{ width: '49%' }}>
          <FormLabel label={t('LEAD.CREATE.GENERAL_INFORMATION.address')} />
          {values.client ? (
            autoCompleteCustom(
              'address',
              !values.client?.addresses || values?.readOnly,
              values.client?.addresses,
              !values.client?.addresses && error,
              values.client?.addresses?.length > 0
                ? capitalizeString(
                    `${t('LEAD.CREATE.GENERAL_INFORMATION.select')} ${t(
                      'LEAD.CREATE.GENERAL_INFORMATION.address'
                    )}`
                  )
                : t('LEAD.CREATE.GENERAL_INFORMATION.dontHave', [
                    t('LEAD.CREATE.GENERAL_INFORMATION.contact')
                  ]),
              values.address
            )
          ) : (
            <RegularInput
              disabled
              customStyle={{ width: '100%' }}
              placeholder={`${t(
                'LEAD.CREATE.GENERAL_INFORMATION.noClientSelected'
              )}`}
            />
          )}
        </Box>
      </Box>
    );
  };

  function description() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <FormLabel
          label={t('LEAD.CREATE.GENERAL_INFORMATION.projectDescription')}
        />
        <RegularInput
          multiline
          customStyle={{ width: '100%' }}
          disabled={values?.readOnly}
          onChange={(description) => setValues({ ...values, description })}
          value={values.description || ''}
        />
      </Box>
    );
  }

  function projectDuration() {
    return (
      <Box
        display="grid"
        sx={{ gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr' }}
      >
        <Box alignItems="center" display="flex" gap="16px">
          <FormLabel
            label={`${t('LEAD.CREATE.GENERAL_INFORMATION.projectType')}:`}
          />

          <FormControl disabled={values?.readOnly}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                const today = dayjs();
                setValues({
                  ...values,
                  endDate:
                    values.endDate && dayjs(values.endDate) >= today
                      ? values.endDate
                      : today,
                  projectType: event.target.value,
                  startDate:
                    values.startDate && dayjs(values.startDate) >= today
                      ? values.startDate
                      : today
                });
              }}
              sx={{ marginTop: 2 }}
            >
              <FormControlLabel
                checked={
                  values.projectType === 'Regular' ||
                  values.projectType === 'regular'
                }
                control={<CustomRadio />}
                label={
                  <Typography variant="primary">
                    {t('LEAD.CREATE.GENERAL_INFORMATION.regular')}
                  </Typography>
                }
                value="Regular"
              />
              <FormControlLabel
                checked={
                  values.projectType === 'adhoc' ||
                  values.projectType === 'Ad-Hoc'
                }
                control={<CustomRadio />}
                label={
                  <Typography variant="primary">
                    {t('LEAD.CREATE.GENERAL_INFORMATION.adhoc')}
                  </Typography>
                }
                value="Ad-Hoc"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box display="flex" mt={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              disabled={values.readOnly || !values.projectType}
              label={t('LEAD.CREATE.startDate')}
              onChange={(startDate) => {
                if (values.endDate && startDate > values.endDate) {
                  setValues({
                    ...values,
                    endDate: startDate,
                    startDate
                  });
                } else {
                  setValues({
                    ...values,
                    startDate
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ backgroundColor: 'transparent !important' }}
                />
              )}
              value={values.startDate}
            />
          </LocalizationProvider>
          {values.projectType !== 'adhoc' &&
            values.projectType !== 'Ad-Hoc' && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  disabled={values.readOnly || !values.projectType}
                  label={t('LEAD.CREATE.endDate')}
                  minDate={values.startDate}
                  onChange={(endDate) => setValues({ ...values, endDate })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ backgroundColor: 'transparent !important' }}
                    />
                  )}
                  value={values.endDate || values.startDate}
                />
              </LocalizationProvider>
            )}
        </Box>
      </Box>
    );
  }

  function documents() {
    return (
      <Box sx={{ marginTop: 2 }}>
        <FormLabel
          label={t('LEAD.CREATE.GENERAL_INFORMATION.uploadDocuments')}
        />
        <br />
        <Dropzone
          multiple
          filesList={values?.fileBank || []}
          isMobile={false}
          parent="lead"
          setFiles={(files) => {
            setValues({
              ...values,
              fileBank: files
            });
          }}
          type="file"
        />
      </Box>
    );
  }

  function SaveBeforeLeaveModal() {
    setConfirmModal(
      () => {
        saveStepper({});
      },
      `${t('GENERAL.confirmButton')}`,
      () => {
        navigate('/app/leads/listing-page');
      },
      `${t('GENERAL.cancelButton')}`,
      <Box>
        <Box p={2}>
          <i
            className="custom-icon icon-info"
            style={{ color: theme.palette.surface?.main, fontSize: 34 }}
          />
        </Box>
        {`${t('LEAD.CREATE.saveProgress')}`}
      </Box>
    );
  }

  function buttons() {
    return (
      <Box
        pt={4}
        sx={{
          display: 'flex',
          float: 'right',
          justifyContent: 'space-between',
          width: 300
        }}
      >
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            if (Object.keys(values).length > 0) {
              SaveBeforeLeaveModal();
              return;
            }
            navigate('/app/leads/listing-page');
          }}
          sx={{ m: 1 }}
          variant="cancel"
        >
          {t('GENERAL.cancelButton')}
        </Button>
        <Button
          fullWidth
          disabled={!validData || values?.readOnly}
          key="next-button-general-info"
          onClick={() => {
            if (Object.keys(values).length === 0) {
              saveStepper({ next: true });
            } else if (
              values.projectType === 'regular' ||
              values.projectType === 'Regular'
            ) {
              setValues({ ...values, manualPricing: false });
              sendValues(values);
              saveStepper({ next: true });
            } else if (!curentLeadId) {
              setShowManualPricingModal(true);
            } else {
              sendValues(values);
              saveStepper({ next: true });
            }
          }}
          sx={{ m: 1 }}
          variant="primary"
        >
          {t('GENERAL.nextButton')}
        </Button>
      </Box>
    );
  }

  function header() {
    return (
      <Box display="flex" justifyContent="space-between" marginBottom="16px">
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          sx={{ width: '60%' }}
        >
          <Typography color="primary" variant="heading_400">
            {`${t('LEAD.CREATE.GENERAL_INFORMATION.description')}`}
          </Typography>
          <Typography color="secondary.400" variant="body_300">
            {`${t('LEAD.CREATE.GENERAL_INFORMATION.leadClientWarning')}`}
          </Typography>
        </Box>

        <Button
          disabled={values?.readOnly}
          onClick={() =>
            navigate('/app/clients/new', {
              state: { ...values, lastPage: '/app/leads/edit-lead' }
            })
          }
          size="large"
          variant="primary"
        >
          <Typography
            sx={{ alignItems: 'center', display: 'flex' }}
            variant="primary"
          >
            <Box display="flex" mr={1}>
              <i className="custom-icon icon-add" />
            </Box>
            {`${t('CLIENT.createClient')}`}
          </Typography>
        </Button>
      </Box>
    );
  }

  //   USEEFFECTS ==========================================================================================

  useEffect(() => {
    setError(false);
    setErrorMsg('');
    setClients([]);
    setAskClients(true);
    if (values?.startDate) {
      const today = dayjs();
      setValues({
        ...values,
        endDate:
          values.endDate && dayjs(values.endDate) >= today
            ? values.endDate
            : today,
        startDate:
          values.startDate && dayjs(values.startDate) >= today
            ? values.startDate
            : today
      });
    }
  }, []);

  useEffect(() => {
    if (clientsError) {
      setClients([]);
    }
  }, [clientsError]);

  useEffect(() => {
    if (clientsData) {
      // setAskClients(false);
      // setAskContacts(true);
      setClients(
        clientsData.map((item: any) => {
          return {
            ...item,
            name: `${item.firstName} ${item.lastName} from ${item.businessName}`
          };
        })
      );
    }
  }, [clientsData]);

  useEffect(() => {
    if (clients?.length > 0) {
      if (params?.clientCreated) {
        const { clientCreated } = params;
        clients.forEach((client: any) => {
          if (client.id === clientCreated.id) {
            setValues({ ...values, client, clientReady: true });
          }
        });
      } else if (values.client) {
        setValues({ ...values, clientReady: true });
      }
    }
  }, [clients, params]);

  useEffect(() => {
    if (
      values?.address?.id &&
      values?.contact?.id &&
      values?.name &&
      values?.projectType &&
      (values?.projectStartDate || values?.startDate)
    ) {
      setValidData(true);
    } else {
      setValidData(false);
    }
  }, [values]);

  //   RENDER ==========================================================================================
  if (!values?.id) {
    return (
      <BoxCentered>
        <Loader customColor="primary" customSize={48} />
      </BoxCentered>
    );
  }

  return (
    <Box sx={{ pointerEvents: values?.readOnly ? 'none' : '' }}>
      <Box>
        {header()}
        <br />
        {clientAndContact()}
        {projectNameAndAddress()}
        {description()}
        {projectDuration()}
        {/* {documents()} */}
        {getError()}
      </Box>
      {buttons()}
      {showManualPricingModal && (
        <ManualorServiceCatalogModal
          saveStepper={saveStepper}
          sendValues={sendValues}
          setShowManualPricingModal={setShowManualPricingModal}
          setValues={setValues}
          values={values}
        />
      )}
    </Box>
  );
};

export default GeneralInformation;
