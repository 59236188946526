import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, styled, SliderProps } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../components/input/RegularInput';
import api from '../../api';
import { formatDateFull } from '../../utils/general';
import Loader from '../../components/shared/Loader';
import DeleteButton from '../../components/list/DeleteButton';
import DeleteModal from '../../components/modals/DeleteModal';

const MsgContainer = styled(Box)<SliderProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2)
}));

interface Props {
  // can be the proposal id or the token
  id?: string;
  messages: Array<any>;
  onClose?: () => void;
  technicianView?: boolean;
  updateMessages?: () => void;
}

const Notes: React.FC<Props> = function Notes({
  id,
  messages,
  updateMessages,
  technicianView,
  onClose
}) {
  const { t } = useTranslation();
  const deleteAction = api.ProjectApi.deleteNotes;
  const [postMessageApi, setPostMessageApi] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<any>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDelete] = useState<string | null>(null);

  const {
    data: postMessageData,
    mutate: postMessageMutate,
    error: postMessageError
  } = useSWR(
    postMessageApi ? { id, text: message, title: '', url: 'postNote' } : null,
    api.ProjectApi.postNote
  );

  const clearApiResponse = () => {
    postMessageMutate(undefined, true);
  };

  // We only can reset the component content disabled enable it
  const inputBox = () => {
    return (
      <Box alignItems="center" display="flex" gap={2} pl={2} pr={2}>
        <Box display="flex" justifyContent="center" width="100%">
          {postMessageApi && (
            <RegularInput
              disabled
              customStyle={{ height: '46px', width: '100%' }}
            />
          )}
          {!postMessageApi && (
            <RegularInput
              customStyle={{ height: '46px', width: '100%' }}
              onChange={(value: any) => {
                setMessage(value);
              }}
              value={message}
            />
          )}
        </Box>
        <Button
          onClick={() => {
            clearApiResponse();
            setPostMessageApi(true);
          }}
          sx={{ width: isMobile ? 50 : 150 }}
          variant="primary"
        >
          <Typography
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}
            variant="primary-bold"
          >
            {!isMobile && t('LEAD.CREATE.PROPOSAL.CHAT.button')}{' '}
            <SendIcon fontSize="small" />
          </Typography>
        </Button>
      </Box>
    );
  };

  const messageItem = (
    name: string,
    date: string,
    text: string,
    sendByType: string,
    id: string
  ) => {
    const order = sendByType === 'operator' ? 0 : 1;
    const align = order ? 'right' : 'left';
    return (
      <MsgContainer>
        <Box
          display="flex"
          flexDirection={technicianView ? 'column' : 'row'}
          justifyContent={technicianView ? 'center' : 'space-between'}
          width="100%"
        >
          <Box
            alignItems="center"
            display="flex"
            gap={2}
            order={order}
            width="100%"
          >
            <Box
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent={technicianView ? 'center' : 'space-between'}
              width="100%"
            >
              {!technicianView && (
                <DeleteButton
                  deleteFn={() => {
                    setDelete(id);
                    setShowDeleteModal(true);
                  }}
                />
              )}
              <Typography color="primary" order={order} variant="heading_300">
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={1}
          justifyContent={technicianView ? 'center' : 'space-between'}
          mt={2}
          textAlign={technicianView ? 'center' : align}
          width="100%"
        >
          <Typography color="secondary" variant="body_200">
            {date}
          </Typography>
          <Typography variant="body_200">{text}</Typography>
        </Box>
      </MsgContainer>
    );
  };

  useEffect(() => {
    if (postMessageData) {
      setMessage('');
      setPostMessageApi(false);
      if (updateMessages) {
        updateMessages();
      }
    }
  }, [postMessageData]);

  useEffect(() => {
    if (postMessageError) {
      setPostMessageApi(false);
      setError({ message: t('ERROR.general') });
    }
  }, [postMessageError]);

  useEffect(() => {
    if (messages) {
      if (messages?.length !== 0) {
        setError(null);
      }
    }
  }, [messages]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight={technicianView ? 500 : 500}
      width="100%"
    >
      <Typography pl={2} variant="heading_400">
        {technicianView ? t('NOTES.titleMobile') : t('NOTES.title')}
      </Typography>

      {!technicianView && inputBox()}
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        height="80%"
        overflow="scroll"
        padding={2}
        style={{ overflowX: 'hidden' }}
      >
        {error && (
          <Typography color="alert.main" variant="caption">
            {error.message}
          </Typography>
        )}
        {!error && !messages && (
          <Loader customColor="primary" customSize={16} />
        )}
        {messages?.length > 0 &&
          messages.map((item: any) => {
            const name = `${item?.operator?.firstName} ${item?.operator?.lastName}`;
            return messageItem(
              name,
              formatDateFull(item?.createdAt),
              item?.noteText,
              item?.sendByType,
              item?.id
            );
          })}
      </Box>
      <br />
      {technicianView && (
        <Box width="100%">
          <Button
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
            sx={{ width: 200 }}
            variant="cancel"
          >
            {t('GENERAL.closeButton')}
          </Button>
        </Box>
      )}
      {showDeleteModal && (
        <DeleteModal
          action={deleteAction}
          id={{ id: deleteItem, url: `/delete/projects-notes-${deleteItem}` }}
          mutate={() => updateMessages && updateMessages()}
          setShowModal={setShowDeleteModal}
        />
      )}
    </Box>
  );
};

Notes.defaultProps = {
  id: '',
  onClose: () => {},
  technicianView: false,
  updateMessages: () => {}
};

export default Notes;
