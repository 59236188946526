import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AutoCompleteSupervisor from '../../../../components/input/AutoCompleteSupervisor';
import { FormLabel } from '../../../../components/form/parts';
import RegularInput from '../../../../components/input/RegularInput';

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  setValuesST: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const AssignSupervisor: React.FC<Props> = function AssignSupervisor({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  setValuesST,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [addHours, setAddHours] = useState(false);
  const [assignSupervisorFlag, setassignSupervisorFlag] = useState<string>(
    values?.serviceTimes?.[index]?.assignSupervisor ? '0' : '1'
  );
  const [supervisorTime, setSupervisorTime] = useState<string>(values.serviceTimes[index]?.supervisorTime || '0');

  const resetState = () => {
    setSupervisorTime('0');
  }
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setassignSupervisorFlag(value);
    const newServices = values.serviceTimes;

    if (value === '1') {
      newServices[index].supervisor = null;
      newServices[index].supervisors = null;
      newServices[index].assignSupervisor = false;
      newServices[index].supervisorTime = '0';
      resetState();
    } else {
      newServices[index].assignSupervisor = true;
    }

    setValues({ ...values, serviceTimes: newServices });
  };

  const handleHoursFieldDisable = () => {
    const disable = values?.serviceTimes[index]?.supervisor?.id ? false : !!values?.serviceTimes[index]?.supervisor?.id || !addHours;
    return values?.readOnly || disable;
  };

  return (
    <div key={`assign-supervisor-div-${index}`}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          marginTop: '40px'
        }}
      >
        <Typography marginX="16px" variant="body_500">
          {t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorTitle')}
        </Typography>
      </Box>
      <Box display="flex" marginTop="16px">
        <RadioGroup onChange={handleChange} value={assignSupervisorFlag}>
          <FormControlLabel
            control={<Radio />}
            disabled={values?.readOnly}
            label={t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisor')}
            value="0"
          />
          <FormControlLabel
            control={<Radio />}
            disabled={values?.readOnly}
            label={t('LEAD.CREATE.SERVICES.SCHEDULE.dontAssingSupervisor')}
            value="1"
          />
        </RadioGroup>
        {assignSupervisorFlag === '0' && (
          <Box
            sx={{
              border: 1,
              borderColor: theme.palette.secondary[400],
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '40px'
            }}
          >
            <Box
              sx={{
                border: 1,
                borderColor: theme.palette.secondary[400],
                borderRadius: 1
              }}
            >
              <Typography color="#2a3845" marginX="16px" variant="body_400">
                {t(
                  'LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorAutocomplete'
                )}
              </Typography>
            </Box>
            <Box marginY="-12px">
              <AutoCompleteSupervisor
                disabled={values?.readOnly}
                multiple={false}
                setValues={(value) => {
                  let auxServicesTimes = values.serviceTimes;
                  auxServicesTimes[index].supervisor = value;
                  setAddHours(!!value.id);
                  if (!value.id) {
                    auxServicesTimes[index].supervisorTime = '0';
                  }
                  auxServicesTimes = {
                    ...auxServicesTimes[index]
                  };
                  setSupervisorTime('0')
                  setValuesST(values);
                }}
                type="supervisor"
                values={values?.serviceTimes?.[index] || []}
              />
            </Box>
          </Box>
        )}
        {assignSupervisorFlag === '0' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: 2,
              width: '170px'
            }}
          >
            <FormLabel
              label={t('LEAD.CREATE.SERVICES.SCHEDULE.assingSupervisorHours')}
            />
            <RegularInput
              customStyle={{ width: '100%' }}
              disabled={handleHoursFieldDisable()}                              
              onChange={(supervisorTime) => {
                let auxServicesTimes = {...values.serviceTimes};
                auxServicesTimes[index].supervisorTime = supervisorTime;
                auxServicesTimes = {
                  ...auxServicesTimes[index]
                };
                setSupervisorTime(supervisorTime);
                setValuesST(values);
              }}
              type="number"
              value={supervisorTime}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default AssignSupervisor;
