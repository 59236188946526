/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Box,
  Typography,
  Avatar,
  Button,
  styled,
  SlideProps,
  useTheme
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import uniqolor from 'uniqolor';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from 'react-calendar-timeline';
import moment from 'moment';
import api from '../../api';
import { formatDate } from '../../utils/general';
import Loader from '../../components/shared/Loader';
import {
  mainColors,
  secondaryColors,
  successColors,
  warningColors
} from '../../theme/themeConfig';
import AssignmentsTask from './AssignmentsTask';
import ConfirmModal from '../../components/modals/ConfirmModal';
import useNavigationStore from '../../store/zustand/navigation';

const keys = {
  groupIdKey: 'id',
  groupLabelKey: 'title',
  groupRightTitleKey: 'rightTitle',
  groupTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemIdKey: 'id',
  itemTimeEndKey: 'end_time',
  itemTimeStartKey: 'start_time',
  itemTitleKey: 'title'
};

interface Props {
  id: string;
  projectEndDate: string;
  projectStartDate: string;
  projectStatus: string;
}

const ServiceButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  margin: '15px',
  width: '130px'
}));

const CheckedIcon = <CheckIcon fontSize="small" />;

const Assignments: React.FC<Props> = function Assignments({
  id,
  projectEndDate,
  projectStartDate,
  projectStatus
}) {
  const { t } = useTranslation();
  const { sidebarOpen } = useNavigationStore((state) => state);
  const [getAssignments, setGetAssignments] = useState<boolean>(false);
  const [groups, setGroups] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fireDelete, setFireDelete] = useState<boolean>(false);
  const [auxVisibleTimeEnd, setAuxVisibleTimeEnd] = useState<any>(null);
  const [auxVisibleTimeStart, setAuxVisibleTimeStart] = useState<any>(null);
  const [width, setWidth] = useState<any>(
    window.innerWidth - (sidebarOpen ? 0 : 186)
  );
  const theme = useTheme();

  const auxStart = moment(projectStartDate);
  const auxEnd = moment(projectEndDate);

  const defaultTimeStart =
    auxEnd.toDate() < new Date()
      ? moment(projectStartDate).toDate()
      : new Date();

  const defaultTimeEnd = moment(defaultTimeStart).add(30, 'days').toDate();

  const minTime = auxStart.add(-1, 'day').valueOf();

  const maxTime =
    auxEnd.diff(auxStart, 'days') < 30 && auxEnd.toDate()
      ? moment(defaultTimeStart).add(30, 'days').valueOf()
      : auxEnd.add(1, 'day').valueOf();

  const { data: assignmentsData, isValidating: isValidatingAssignments } =
    useSWR(
      getAssignments ? { id, url: `/assignments/${id}` } : null,
      api.ProjectApi.getAssigments
    );

  const {
    data: unassignmentData,
    error: unassignmentDataError,
    isValidating: isValidatingUnassignment
  } = useSWR(
    fireDelete && items.filter((item: any) => item.selected).length > 0
      ? {
          assignmentsIdsList: items.filter((item: any) => item.selected),
          projectId: id
        }
      : null,
    api.ProjectApi.unassignResources
  );

  const selectGroup = (group: any) => {
    const selectStatus = !group?.selected;

    const auxIndex = groups.findIndex(
      (groupData: any) => groupData.id === group.id
    );
    const auxGroups = groups;
    auxGroups[auxIndex].selected = !auxGroups[auxIndex].selected;

    setGroups(
      groups.map((groupData: any) => {
        return {
          ...groupData,
          selected: groupData.id === group.id ? selectStatus : false
        };
      })
    );

    setItems(
      items.map((itemData: any) => {
        if (
          (!itemData?.status || itemData.status !== 'Completed') &&
          itemData.group === group.id &&
          moment(itemData.end_time).toDate() > moment().toDate() &&
          (group.level === 3 || group.level === 2)
        ) {
          return {
            ...itemData,
            selected: selectStatus
          };
        }

        return {
          ...itemData,
          selected: false
        };
      })
    );
  };

  const itemSelect = (item: any) => {
    const auxDate = items.find((dateInfo: any) => dateInfo.id === item.id);
    const fiteredItems = items.filter(
      (dateInfo: any) => dateInfo.id !== item.id
    );

    if (
      items.filter((dateInfo: any) => dateInfo.selected).length > 0 &&
      items
        .filter((dateInfo: any) => dateInfo.selected)
        .every(
          (dateInfo: any) =>
            dateInfo.taskId === item.taskId &&
            ((item.technicianId &&
              dateInfo.technicianId &&
              item.technicianId === dateInfo.technicianId) ||
              (!item.technicianId && !dateInfo.technicianId))
        )
    ) {
      setItems([...fiteredItems, { ...auxDate, selected: !auxDate.selected }]);
    } else {
      setItems([
        ...fiteredItems.map((dateInfo: any) => {
          return { ...dateInfo, selected: false };
        }),
        { ...auxDate, selected: !auxDate.selected }
      ]);
    }
  };

  const itemRenderer = ({ item, getItemProps }: any) => {
    let disableClick = false;
    const completeAssignments = false;
    let backGround = `${mainColors.MAIN_1} !important`;
    let auxBorderColor = `${mainColors.MAIN_3} !important`;

    if (
      moment(item.end_time).toDate() < moment().toDate() ||
      (item?.status &&
        (item.status === 'Completed' || item.status === 'Started'))
    ) {
      disableClick = true;
    }

    // if (
    //   (!item?.technicianId &&
    //     item.currentAssignments > 0 &&
    //     item.currentAssignments >= item.requiredAssignments) ||
    //   (item?.status && item.status === 'Completed')
    // ) {
    //   completeAssignments = true;
    // }

    if (item?.assignmentId) {
      backGround = `${successColors.SUCCESS_2} !important`;
      auxBorderColor = `${successColors.SUCCESS_4} !important`;
    }

    if (item?.selected) {
      backGround = `${successColors.SUCCESS_5} !important`;
      auxBorderColor = `${successColors.SUCCESS_5} !important`;
    }

    if (disableClick) {
      backGround = `${secondaryColors.SECONDARY_1} !important`;
      auxBorderColor = `${secondaryColors.SECONDARY_2} !important`;
    }

    if (
      item?.status &&
      (item.status === 'Completed' || item.status === 'Started')
    ) {
      backGround = `${successColors.SUCCESS_3} !important`;
      auxBorderColor = `${successColors.SUCCESS_4} !important`;
    }

    const auxProps = getItemProps({
      style: {
        alignItems: 'top',
        background: backGround,
        borderRadius: '50%',
        borderRightWidth: 1,
        // boxShadow: 'rgba(0, 0, 0, 0.16) 0 0.3rem 0.6rem',
        cursor: disableClick || completeAssignments ? 'not-allowed' : 'pointer',
        display: 'flex',
        // height: 27px;
        filter: disableClick ? 'brightness(0.6)' : 'brightness(1)',
        marginTop: '1px'
      }
    });

    return (
      <Box
        {...auxProps}
        className="item-box-container-assignments double-item-assignments"
        onClick={() => {
          return disableClick || completeAssignments ? null : itemSelect(item);
        }}
        sx={{
          backgroundColor: backGround,
          borderColor: auxBorderColor
        }}
        title={`${item.title}${
          completeAssignments ? ' - Complete assignment' : ''
        }`}
      >
        <Box
          className="item-box-container-assignments-content"
          sx={{
            // border: `1px solid ${auxBorderColor}`,
            display: 'flex',
            justifyContent: 'center',
            left: '0',
            overflow: 'hidden',
            padding: '0',
            position: 'sticky',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%'
          }}
        >
          <Typography
            fontSize={16}
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
            variant="body_200"
          >
            {completeAssignments ? (
              <i
                className="custom-icon icon-userSelected"
                style={{ fontSize: 14 }}
              />
            ) : (
              ''
            )}
            {item?.selected ? CheckedIcon : ''}
          </Typography>
        </Box>
      </Box>
    );
  };

  const generateNestedView = useCallback(
    (group: any) => {
      let backGroundColor = `${secondaryColors.SECONDARY_6} !important`;
      if (group.level === 0) {
        return (
          <Box
            className="group-service-time-assignments"
            style={{
              background: backGroundColor,
              backgroundColor: backGroundColor,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'block' }}>
              <Box sx={{ display: 'flex', ml: '0.5rem', mt: '0.5rem' }}>
                <Typography
                  fontFamily={theme.typography.primaryBase.font}
                  variant="body_200"
                >
                  {group.title}
                </Typography>
                {/*
                <Box sx={{ display: 'flex', ml: '0.5rem' }}>
                  <Box sx={{ display: 'flex', height: '25px' }}>
                    <i className="custom-icon icon-clock" />
                  </Box>
                  <Typography sx={{ ml: '0.5rem' }} variant="body_200">
                    {group?.startTime || ''} - 05:00 PM
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Box>
        );
      }
      let auxPaddingLeft = 1;

      if (group.level === 2) {
        auxPaddingLeft = 2;
        backGroundColor = `${mainColors.MAIN_1} !important`;
      }

      if (group.level === 3) {
        auxPaddingLeft = 2;
        backGroundColor = `${successColors.SUCCESS_1} !important`;
      }

      return (
        <Box
          className={group.level !== 0 ? 'group-service-assignments' : ''}
          onClick={() => {
            return group.level === 2 || group.level === 3
              ? selectGroup(group)
              : null;
          }}
          sx={{
            alignItems: 'center',
            backgroundColor: backGroundColor,
            cursor: 'pointer',
            display: 'flex',
            height: '28px',
            justifyContent: 'space-between',
            paddingLeft: auxPaddingLeft,
            paddingRight: '1rem'
          }}
          title={`${group.title} Rate: $${group?.rate}`}
        >
          <Typography
            fontFamily={theme.typography.primaryBase.font}
            variant="body_200"
          >
            {group.title} {/* group?.rate && ` - Rate: $${group?.rate}` */}
          </Typography>{' '}
          {group.level === 2 && (
            <Typography
              fontFamily={theme.typography.primaryBase.font}
              variant="body_200"
            >
              {group?.details}
            </Typography>
          )}
          {group.level === 3 && group?.initials && (
            <Box sx={{ width: '28px' }}>
              <Avatar
                alt={group.title}
                src=""
                sx={{
                  backgroundColor: uniqolor(group?.technicianId || '').color,
                  height: '22px',
                  marginRight: '-20px',
                  position: 'relative',
                  width: '22px'
                }}
              >
                <Typography
                  color="white"
                  fontFamily={theme.typography.primaryBase.font}
                  variant="body_200"
                >
                  {group?.initials}
                </Typography>
              </Avatar>
            </Box>
          )}
        </Box>
      );
    },
    [groups]
  );

  useEffect(() => {
    if (assignmentsData) {
      setGetAssignments(false);
      const { groups: newGroups, items: newItems } = assignmentsData;
      setGroups(newGroups);
      setItems(newItems);
      setIsModalOpen(false);

      if (auxVisibleTimeStart && auxVisibleTimeEnd) {
        // setDefaultTimeStart(auxVisibleTimeStart);
        // setDefaultTimeEnd(auxVisibleTimeEnd);
      }
    }
  }, [assignmentsData]);

  useEffect(() => {
    setLoading(true);
    if (sidebarOpen) {
      setWidth(width - 186);
    } else {
      setWidth(width + 186);
    }
  }, [sidebarOpen]);

  useEffect(() => {
    if (
      unassignmentData &&
      !unassignmentDataError &&
      !isValidatingUnassignment
    ) {
      setFireDelete(false);
      const { groups: newGroups, items: newItems } = unassignmentData;
      setGroups(newGroups);
      setItems(newItems);

      if (auxVisibleTimeStart && auxVisibleTimeEnd) {
        // setDefaultTimeStart(auxVisibleTimeStart);
        // setDefaultTimeEnd(auxVisibleTimeEnd);
      }

      setIsModalOpen(false);
    }
  }, [unassignmentData]);

  useEffect(() => {
    setGetAssignments(true);
    const handleResize = () => {
      setLoading(true);
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTimeChange = (
    visibleTimeStart: any,
    visibleTimeEnd: any,
    updateScrollCanvas: any
  ) => {
    setAuxVisibleTimeStart(visibleTimeStart);
    setAuxVisibleTimeEnd(visibleTimeEnd);

    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(
        minTime,
        minTime + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(
        maxTime - (visibleTimeEnd - visibleTimeStart),
        maxTime
      );
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  const transformedGroups = useMemo(() => {
    if (!groups) {
      return [];
    }
    return groups.map((group) => {
      return { ...group, title: generateNestedView(group) };
    });
  }, [groups, items]);

  useEffect(() => {
    if (width) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [width]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: 'auto' }}
      width="100%"
    >
      <Typography pl={2} variant="heading_400">
        {t('GENERAL.assignments')}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <ServiceButton
          color="primary"
          disabled={
            items.filter(
              (dateInfo: any) => dateInfo.selected && !dateInfo?.technicianId
            ).length === 0 ||
            projectStatus === 'Completed' ||
            projectStatus === 'Cancelled'
          }
          onClick={() => setOpenDetail(true)}
          variant="contained"
        >
          <Typography variant="body_200">{t('GENERAL.assign')}</Typography>
        </ServiceButton>
        <ServiceButton
          color="success"
          disabled={
            items.filter(
              (dateInfo: any) => dateInfo.selected && dateInfo?.technicianId
            ).length === 0 ||
            projectStatus === 'Completed' ||
            projectStatus === 'Cancelled'
          }
          onClick={() => setOpenDetail(true)}
          variant="contained"
        >
          <Typography variant="body_200">{t('GENERAL.reassign')}</Typography>
        </ServiceButton>
        <ServiceButton
          color="error"
          disabled={
            items.filter(
              (dateInfo: any) => dateInfo.selected && dateInfo?.technicianId
            ).length === 0 ||
            projectStatus === 'Completed' ||
            projectStatus === 'Cancelled'
          }
          onClick={() => setIsModalOpen(true)}
          variant="contained"
        >
          <Typography variant="body_200">{t('GENERAL.unassign')}</Typography>
        </ServiceButton>
      </Box>
      {isValidatingAssignments ||
        isValidatingUnassignment ||
        (loading && <Loader customColor="primary" />)}
      {!isValidatingAssignments &&
        !isValidatingUnassignment &&
        transformedGroups &&
        items &&
        items.length > 0 && (
          <Box sx={{ overflow: 'scroll' }}>
            {!loading && (
              <Box sx={{}}>
                <Timeline
                  itemTouchSendsClick
                  stackItems
                  traditionalZoom
                  buffer={1}
                  className="calendar-header-blue-open"
                  defaultTimeEnd={defaultTimeEnd}
                  defaultTimeStart={defaultTimeStart}
                  groups={transformedGroups}
                  itemHeightRatio={1}
                  itemRenderer={itemRenderer}
                  items={items}
                  keys={keys}
                  lineHeight={30}
                  maxZoom={30 * 86400 * 1000}
                  minZoom={30 * 86400 * 1000}
                  onTimeChange={handleTimeChange}
                  scrollRef={() => {
                    return null;
                  }}
                  sidebarWidth={250}
                  timeSteps={{
                    day: 1,
                    hour: 1,
                    minute: 1,
                    month: 1,
                    second: 1,
                    year: 1
                  }}
                >
                  <TimelineHeaders
                    style={{ backgroundColor: mainColors.MAIN_3 }}
                  >
                    <SidebarHeader>
                      {({ getRootProps }) => {
                        return (
                          <Box
                            {...getRootProps()}
                            sx={{ backgroundColor: mainColors.MAIN_3 }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '0.5rem 1rem 0rem 1rem'
                              }}
                            >
                              <Box>
                                <Box sx={{ display: 'block' }}>
                                  <Typography
                                    sx={{ color: 'white' }}
                                    variant="body_200"
                                  >
                                    Start: {formatDate(projectStartDate)}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'block' }}>
                                  <Typography
                                    sx={{ color: 'white' }}
                                    variant="body_200"
                                  >
                                    End: {formatDate(projectEndDate)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      }}
                    </SidebarHeader>
                    <DateHeader
                      className="primaryHeader12"
                      height={30}
                      unit="primaryHeader"
                    />
                    <DateHeader className="primaryHeader22" />
                  </TimelineHeaders>
                </Timeline>
              </Box>
            )}
          </Box>
        )}
      {openDetail && (
        <AssignmentsTask
          datesList={items.filter((item: any) => item.selected) || []}
          setOpenDetail={setOpenDetail}
          setParentGroups={setGroups}
          setParentItems={setItems}
          taskData={{
            ...items.filter((item: any) => item.selected)[0],
            projectId: id
          }}
        />
      )}
      {isModalOpen && (
        <ConfirmModal
          modalText={t('MODAL.confirmModalUnassign')}
          modalType="status"
          setSentFlag={setFireDelete}
          setShowModal={setIsModalOpen}
          setShowOkModal={() => {
            return false;
          }}
        />
      )}
    </Box>
  );
};

export default Assignments;
