/* eslint-disable import/prefer-default-export */
import { weekDays } from '../helpers/weekDays';
import {
  getMonthString,
  getNumberForDatesParser,
  statusStyle
} from './general';

export const emptyConfig = {
  chartType: null,
  description: null,
  itemCounter: 0,
  name: null,
  periodConfig: null,
  primaryDataType: null,
  secondaryDataType: null,
  timeConfig: null
};

export function customSort(target: any, reference: any) {
  const orderMap: any = new Map(
    reference.map((item: any, index: number) => [item, index])
  );
  const toSort = target.sort((a: any, b: any) => {
    if (orderMap.has(a) && orderMap.has(b)) {
      return orderMap.get(a) - orderMap.get(b);
    }
    if (orderMap.has(a)) return -1;
    if (orderMap.has(b)) return 1;
    return 0;
  });
  return toSort;
}

export const StatusReference = [
  // Aactive
  'Draft',
  'Created',
  'Updated',
  // Warning
  'Awaiting',
  'Working Lead',
  'Pending',
  'PENDING',
  // Success
  'Converted',
  'Approved',
  'Paid',
  'PAID',
  // Error
  'Overdue',
  'OVERDUE',
  'Cancelled',
  'CANCELLED',
  'Lost',
  'Retrieved',
  'Rejected'
];

export function weekDateParser(d: string) {
  // DD Month
  const date = new Date(d);
  return `${getNumberForDatesParser(date.getDate())} ${
    getMonthString('short')[date.getMonth()]
  }`;
}

export function dayDateParser(d: string) {
  // Wed MM/DD
  const date = new Date(d);
  return `${
    weekDays[date.getDay() === 0 ? 6 : date.getDay() - 1].short
  } ${getNumberForDatesParser(date.getMonth() + 1)}/${getNumberForDatesParser(
    date.getDay()
  )}`;
}

export function dayFullDateParser(d: string) {
  // Wed, OCT DD, YYYY
  const date = new Date(d);
  return `${weekDays[date.getDay() === 0 ? 6 : date.getDay() - 1].short}, ${
    getMonthString('short')[date.getMonth()]
  } ${getNumberForDatesParser(date.getDay())}, ${date.getFullYear()}`;
}

export function fullDateParser(d: string) {
  // OCT DD, YYYY
  const date = new Date(d);
  return `${getMonthString('short')[date.getMonth()]} ${getNumberForDatesParser(
    date.getDay()
  )}, ${date.getFullYear()}`;
}

export function monthDateParser(d: string) {
  const date = new Date(d);
  return getMonthString('short')[date.getMonth()];
}

export function yearDateParser(d: string) {
  const date = new Date(d);
  return date.getFullYear();
}

export function keyParser(keys: [], type: string, nestedData: boolean) {
  if (nestedData) {
    return keys.map((item: any) => {
      const dates = item.split('-');
      switch (type) {
        case 'Monthly': {
          // Sep
          return monthDateParser(dates[0]);
        }
        case 'Daily': {
          // Sun 06/24
          return dayDateParser(dates[0]);
        }
        case 'Biweekly':
        case 'Weekly': {
          // 01 Jan - 14 Jan
          // 01 Jan - 07 Jan
          return `${weekDateParser(dates[0])} - ${weekDateParser(dates[1])}`;
        }
        case 'Quarterly':
        case 'Biannually': {
          // Sep - Nov
          // Sep - Feb
          return `${monthDateParser(dates[0])} - ${monthDateParser(dates[1])}`;
        }
        case 'Annually': {
          // 2024
          return yearDateParser(dates[0]);
        }
        default:
          return [];
      }
    });
  }
  return customSort(keys, StatusReference);
}

export function seriesParser(data: [], nestedData: boolean) {
  const keys: any = Object.keys(data);
  if (nestedData) {
    const secondary = customSort(Object.keys(data[keys[0]]), StatusReference);
    return secondary.map((name: string) => {
      return {
        data: keys.map((key: any) => {
          const item = data[key];
          return Number(item[name]);
        }),
        name
      };
    });
  }
  return customSort(keys, StatusReference).map((key: any) => Number(data[key]));
}

export function colorsParser(data: [], nestedData: boolean) {
  const keys: any = Object.keys(data);
  if (nestedData) {
    const secondary = customSort(Object.keys(data[keys[0]]), StatusReference);
    return secondary.map((name: string) => {
      return statusStyle(name).active;
    });
  }
  return customSort(keys, StatusReference).map(
    (name: string) => statusStyle(name).active
  );
}

export function customWidthChart(data: [], nestedData: boolean) {
  const keys: any = Object.keys(data);
  let width = 0;
  if (nestedData) {
    width = Object.keys(data[keys[0]]).length * keys.length;
  } else {
    width = keys.length;
  }
  return width < 10 ? 10 : width;
}

export function formatValuesToUSDCurrency(amount: number) {
  if (Number.isNaN(amount) || !Number.isFinite(amount)) {
    throw new Error('Invalid number provided');
  }

  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency'
  });

  return formatter.format(amount);
}
