/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomRadio from '../../../../components/form/CustomRadio';
import DaysOfTheWeek from '../../../../components/input/DaysOfTheWeek';
import useNavigationStore from '../../../../store/zustand/navigation';
import TimeComponent from '../../../../components/input/TimeComponent';
import AddServiceModal from '../../../../components/modals/AddServiceModal';
import Filter from '../../../../components/list/Filter';
import { weekDays } from '../../../../helpers/weekDays';
import { ServiceCollapse } from '../components/ServiceCollapse';

interface Props {
  indexServiceTime: number;
  projectEnd: any;
  projectStart: any;
  projectType: string;
  saveStepper: ({ next }: any) => void;
  // eslint-disable-next-line react/require-default-props
  serviceTimeId?: number;
  setCreateServiceTime: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentServiceTime: React.Dispatch<React.SetStateAction<number>>;
  setServiceTimeUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceTime: React.FC<Props> = function ServiceTime({
  values,
  setValues,
  indexServiceTime,
  projectStart,
  projectEnd,
  setCurrentServiceTime,
  setCreateServiceTime,
  setServiceTimeUpdate,
  projectType,
  saveStepper,
  serviceTimeId
}) {
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const [serviceFromModal, setServiceFromModal] = useState<any>();
  const [addService, setAddService] = useState<boolean>(false);
  const [loadValuesServTime, setLoadValuesServTime] = useState<boolean>(true);

  const [valuesServTime, setValuesServTime] = useState<any>(
    loadValuesServTime &&
      values?.serviceTimes[indexServiceTime] &&
      values?.serviceTimes[indexServiceTime]?.id
      ? {
          ...values?.serviceTimes[indexServiceTime],
          services: values?.serviceTimes[indexServiceTime]?.services?.filter(
            (service: any) => service?.name
          )
        }
      : []
  );
  useEffect(() => {
    if (valuesServTime?.id) {
      setLoadValuesServTime(false);
    }
  }, [valuesServTime]);

  const weekdays = useMemo(() => {
    return weekDays.map((day: any) => {
      return day.name;
    });
  }, []);

  function ServiceHeader() {
    return (
      <Box display="flex" justifyContent="space-between">
        {projectType === 'regular' || projectType === 'Regular' ? (
          <Box display="flex" flexDirection="column">
            <Typography sx={{ fontSize: 30 }} variant="body_500">
              {t('LEAD.CREATE.SERVICES.serviceTimeTitle', [
                indexServiceTime ? indexServiceTime + 1 : 1
              ])}
            </Typography>
            <Typography sx={{ mt: 2 }} variant="body_200">
              {t('LEAD.CREATE.SERVICES.serviceTimeDescription')}
            </Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <Typography sx={{ fontSize: 30 }} variant="body_500">
              {t('LEAD.CREATE.SERVICES.adhocHeaderTitle')}
            </Typography>
            <Typography sx={{ mt: 2 }} variant="body_200">
              {t('LEAD.CREATE.SERVICES.adhocHeaderDescription')}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box textAlign="right" width={250}>
            <Typography variant="body_200">
              {`${t('LEAD.CREATE.SERVICES.MANUAL_PRICING.address')}: ${
                values?.address?.address ||
                valuesServTime?.address?.address ||
                t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
              }`}
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="body_200">
              {`${t('LEAD.CREATE.SERVICES.MANUAL_PRICING.phone')}: ${
                values?.contact?.phoneNumber ||
                valuesServTime?.contact?.phoneNumber ||
                t('LEAD.CREATE.SERVICES.MANUAL_PRICING.noData')
              }`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  function ServiceTimeConfiguration() {
    return (
      <Box display="flex" flexDirection="column">
        {ServiceHeader()}
        {(projectType === 'regular' || projectType === 'Regular') && (
          <Box>
            <Box display="flex" p={2}>
              <Typography sx={{ pt: 2, width: 200 }} variant="primary">
                {t('LEAD.CREATE.SERVICES.serviceFrequency')}:
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(event: any) => {
                    const frequency = event.target.value;
                    setValuesServTime({
                      ...valuesServTime,
                      frequency: event.target.value,
                      frequencyDays:
                        frequency === 'Monthly' &&
                        valuesServTime.frequencyDays?.length > 0
                          ? [valuesServTime.frequencyDays[0]]
                          : valuesServTime.frequencyDays
                    });
                  }}
                  sx={{ mt: 2 }}
                >
                  <FormControlLabel
                    checked={valuesServTime?.frequency === 'Weekly'}
                    control={<CustomRadio />}
                    label={
                      <Typography variant="primary">
                        {t('LEAD.CREATE.SERVICES.weekly')}
                      </Typography>
                    }
                    value="Weekly"
                  />
                  <FormControlLabel
                    checked={valuesServTime?.frequency === 'Biweekly'}
                    control={<CustomRadio />}
                    label={
                      <Typography variant="primary">
                        {t('LEAD.CREATE.SERVICES.biweekly')}
                      </Typography>
                    }
                    value="Biweekly"
                  />
                  <FormControlLabel
                    checked={valuesServTime?.frequency === 'Monthly'}
                    control={<CustomRadio />}
                    label={
                      <Typography variant="primary">
                        {t('LEAD.CREATE.SERVICES.monthly')}
                      </Typography>
                    }
                    value="Monthly"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {valuesServTime?.frequency &&
              valuesServTime.frequency === 'Monthly' && (
                <Box display="flex" p={2}>
                  <Typography sx={{ pt: 2, width: 200 }} variant="primary">
                    {t('LEAD.CREATE.SERVICES.frequencyWeek')}:
                  </Typography>
                  <Filter
                    arrayInput={['First', 'Second', 'Third', 'Fourth']}
                    selectInput={valuesServTime?.frequencyWeek}
                    setSelectInput={(frequencyWeek) => {
                      setValuesServTime({ ...valuesServTime, frequencyWeek });
                    }}
                    title="Week"
                  />
                  <Filter
                    arrayInput={weekdays}
                    selectInput={valuesServTime?.frequencyDays?.[0]}
                    setSelectInput={(frequencyDays) => {
                      setValuesServTime({
                        ...valuesServTime,
                        frequencyDays: [frequencyDays]
                      });
                    }}
                    title="Day"
                  />
                  <Typography sx={{ pt: 2, width: 200 }} variant="primary">
                    {t('LEAD.CREATE.SERVICES.frequencyWeek2')}
                  </Typography>
                </Box>
              )}
            {valuesServTime && valuesServTime.frequency !== 'Monthly' && (
              <Box display="flex" p={2}>
                <Typography sx={{ pt: 1, width: 200 }} variant="primary">
                  {t('LEAD.CREATE.SERVICES.daysOfTheWeek')}:
                </Typography>
                <Box display="ruby-text">
                  <Button
                    onClick={() => {
                      if (valuesServTime?.frequencyDays.length === 7) {
                        setValuesServTime({
                          ...valuesServTime,
                          frequencyDays: []
                        });
                      } else {
                        setValuesServTime({
                          ...valuesServTime,
                          frequencyDays: weekDays.map((day) => day.name)
                        });
                      }
                    }}
                    sx={{ marginBottom: '-2.5rem', marginTop: '-1.75rem' }}
                  >
                    {t('LEAD.CREATE.SERVICES.allDays')}
                  </Button>
                  <DaysOfTheWeek
                    disabled={!valuesServTime?.frequency}
                    setValues={(frequencyDays) => {
                      setValuesServTime({ ...valuesServTime, frequencyDays });
                    }}
                    type="box"
                    values={valuesServTime?.frequencyDays || []}
                  />
                </Box>
              </Box>
            )}
            <Box display="flex" p={2}>
              <Typography sx={{ width: 190 }} variant="primary">
                {t('LEAD.CREATE.SERVICES.timePeriod')}:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={`${t('LEAD.CREATE.startDate')}`}
                  minDate={dayjs(projectStart) || undefined}
                  onChange={(startDate) => {
                    if (
                      (valuesServTime.endDate && startDate) ||
                      projectStart > valuesServTime.endDate
                    ) {
                      setValuesServTime({
                        ...valuesServTime,
                        endDate: projectEnd,
                        startDate
                      });
                    } else {
                      setValuesServTime({
                        ...valuesServTime,
                        startDate
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ backgroundColor: 'transparent !important' }}
                    />
                  )}
                  value={valuesServTime?.startDate || projectStart}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('LEAD.CREATE.endDate')}
                  maxDate={dayjs(projectEnd) || undefined}
                  onChange={(endDate) =>
                    setValuesServTime({ ...valuesServTime, endDate })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ backgroundColor: 'transparent !important' }}
                    />
                  )}
                  value={dayjs(valuesServTime.endDate || projectEnd)}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        )}
        <Box display="flex" p={2}>
          <Box display="flex" flexDirection="column" mt={2} width={200}>
            <Typography variant="primary">
              {t('LEAD.CREATE.SERVICES.travelTime')}:
            </Typography>
            <Typography variant="body_100">
              {`(${t('LEAD.CREATE.SERVICES.roundTrip')})`}
            </Typography>
          </Box>
          <TimeComponent
            readOnly={values?.readOnly}
            setTime={(travelTime) =>
              setValuesServTime({
                ...valuesServTime,
                travelTime: parseFloat(travelTime.toFixed(2))
              })
            }
            time={valuesServTime.travelTime || 0}
          />
        </Box>
        <Box p={2} />
      </Box>
    );
  }

  function Buttons() {
    const formatTime = (time: string) => {
      const timeTZ = dayjs()
        .hour(parseFloat(time.split(':')[0]))
        .minute(parseFloat(time.split(':')[1]))
        .add(-values.address.timezone, 'minute');
      if (
        timeTZ?.hour().toString().length === 1 &&
        timeTZ?.minute().toString().length === 1
      ) {
        return `0${timeTZ?.hour()}:0${timeTZ?.minute()}`;
      }
      if (timeTZ?.hour().toString().length === 1) {
        return `0${timeTZ?.hour()}:${timeTZ?.minute()}`;
      }
      if (timeTZ?.minute().toString().length === 1) {
        return `${timeTZ?.hour()}:0${timeTZ?.minute()}`;
      }
      return `${timeTZ?.hour()}:${timeTZ?.minute()}`;
    };

    return (
      <Box
        pt={4}
        sx={{
          display: 'flex',
          float: 'right',
          justifyContent: 'space-between',
          width: 300
        }}
      >
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            if (serviceTimeId === -1) {
              const cleanedServicesTimes = values?.serviceTimes?.filter(
                (item: any) =>
                  item?.id &&
                  typeof item?.id === 'number' &&
                  item?.id !== serviceTimeId
              );
              setValues({ ...values, serviceTimes: cleanedServicesTimes });
            }

            setValuesServTime({});
            setCurrentServiceTime(-1);
          }}
          sx={{ mr: 1 }}
          variant="cancel"
        >
          {t('GENERAL.cancelButton')}
        </Button>
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            const today = dayjs(projectStart);
            if (typeof valuesServTime.startDate === 'undefined') {
              valuesServTime.startDate = today;
            }

            if (typeof valuesServTime.endDate === 'undefined') {
              valuesServTime.endDate = projectEnd || today;
            }

            if (values?.serviceTimes[indexServiceTime]?.id) {
              setValuesServTime({
                ...valuesServTime,
                id: values?.serviceTimes[indexServiceTime].id
              });
            }

            valuesServTime.startTime = formatTime('08:00');
            valuesServTime.endTime = formatTime('18:00');

            setValues(valuesServTime);

            if (valuesServTime?.id) {
              setServiceTimeUpdate(true);
            } else {
              setCreateServiceTime(true);
            }
            // setCurrentServiceTime(-1);
            // saveStepper({ next: 2 });
          }}
          variant="primary"
        >
          {t('GENERAL.nextButton')}
        </Button>
      </Box>
    );
  }

  function ServicesHeader() {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box>
          <Typography sx={{ fontSize: 30 }} variant="body_500">
            {t('LEAD.CREATE.SERVICES.services')}
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={values?.readOnly}
            onClick={() => {
              setConfirmModal(
                () => {
                  setAddService(true);
                  // setCreateServiceTime(true);
                },
                `${t('GENERAL.addServiceButton')}`,
                () => {},
                `${t('GENERAL.cancelButton')}`,
                <AddServiceModal
                  setValue={(value) => {
                    setServiceFromModal(value);
                  }}
                />
              );
            }}
            variant="primary"
          >
            {t('LEAD.CREATE.SERVICES.addService')}
          </Button>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (!valuesServTime?.length) {
      setLoadValuesServTime(true);
    } else {
      setLoadValuesServTime(false);
    }

    if (valuesServTime?.startDate && valuesServTime?.endDate) {
      const today = dayjs();
      setValuesServTime({
        ...valuesServTime,
        endDate:
          valuesServTime.endDate && dayjs(valuesServTime.endDate) >= today
            ? valuesServTime.endDate
            : projectEnd,
        startDate:
          valuesServTime.startDate && dayjs(valuesServTime.startDate) >= today
            ? valuesServTime.startDate
            : today
      });
    } else {
      const date = new Date(values?.startDate || values?.projectStartDate);
      // date.setDate(date.getDate() + 1);
      const weekDay = weekDays[date.getDay()];
      setValuesServTime({
        ...valuesServTime,
        endDate: values.projectEnd || values.projectEnd,
        frequency: values?.fequency || 'Weekly',
        frequencyDays: [weekDay.name],
        startDate: values.projectStartDate || values.startDate
      });
    }
  }, []);

  useEffect(() => {
    if (addService) {
      const auxValuesServices =
        valuesServTime?.services?.length > 0
          ? valuesServTime.services.map((service: any) => {
              let tasks = [];

              if (service?.tasks?.length > 0) {
                tasks = service.tasks.map((task: any) => {
                  let materials = [{}];

                  if (task?.materials?.length > 0) {
                    materials = task.materials.map((materialData: any) => {
                      return {
                        cost: parseFloat(materialData.cost),
                        materialId: materialData.materialId,
                        name: materialData.name
                      };
                    });
                  }

                  return {
                    ...task,
                    materials
                  };
                });
              }

              return {
                ...service,
                tasks
              };
            })
          : [];

      setAddService(false);
      setValuesServTime({
        ...valuesServTime,
        services: valuesServTime.services
          ? [...auxValuesServices, serviceFromModal]
          : [serviceFromModal]
      });
      setServiceFromModal(null);
    }
  }, [addService]);

  return (
    <Box pb={4}>
      {valuesServTime && (
        <Box>
          {ServiceTimeConfiguration()}
          {ServicesHeader()}
          {valuesServTime.services &&
            valuesServTime.services.length > 0 &&
            valuesServTime?.services.map((service: any, indexKey: number) => {
              return (
                <ServiceCollapse
                  editableFields={[
                    'name',
                    'quantity',
                    'totalTime',
                    'showDetail',
                    'edit',
                    'delete'
                  ]}
                  headKeys={[
                    'name',
                    'quantity',
                    'totalTime',
                    'showDetail',
                    'edit',
                    'delete'
                  ]}
                  indexST={indexServiceTime}
                  manualPricing={valuesServTime.manualPricing}
                  screen="services"
                  service={{ ...service, readOnly: values?.readOnly }}
                  setService={(serviceNewValue: any) => {
                    const { readOnly, ...newValue } = serviceNewValue;
                    const newService = valuesServTime.services;
                    newService[indexKey] = newValue;
                    if (!newValue) {
                      newService.splice(indexKey, 1);
                    }
                    setValuesServTime({
                      ...valuesServTime,
                      services: newService
                    });
                  }}
                  totalServices={valuesServTime.services.length}
                />
              );
            })}
          {!values?.manualPricing}
        </Box>
      )}
      {Buttons()}
    </Box>
  );
};

export default ServiceTime;
